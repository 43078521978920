export default function formatContentfulEntry(entry) {
  const { fields, sys } = entry
  const { id, createdAt, updatedAt, type } = sys || {}

  if (type === 'Link') {
    return sys
  }

  if (!fields) {
    return entry
  }

  const formatted = {}

  if (id) {
    formatted.id = id
  }

  const contentType = sys?.contentType?.sys.id
  if (contentType) {
    formatted.contentType = contentType
  }

  if (createdAt) {
    formatted.createdAt = createdAt
  }

  if (updatedAt) {
    formatted.updatedAt = updatedAt
  }

  return { ...formatted, ...formatFields(fields, contentType) }
}

function formatFields(fields, contentType) {
  const formatted = {}
  Object.entries(fields).forEach(([key, value]) => {
    if (hasCustomFormat(contentType, key)) {
      const customFormat = getCustomFormat(contentType, key)
      formatted[key] = customFormat(value)
    } else if (isContentfulEntry(value)) {
      formatted[key] = formatContentfulEntry(value)
    } else if (Array.isArray(value)) {
      formatted[key] = formatArrayValue(value)
    } else if (isImageFile(key, value)) {
      formatted.size = value.details.image
      formatted.src = value.url
    } else {
      formatted[key] = value
    }
  })

  return formatted
}

function isContentfulEntry(value) {
  return !!value?.sys
}

function formatArrayValue(value) {
  return value.map(val => {
    if (isContentfulEntry(val)) {
      return formatContentfulEntry(val)
    } else {
      return val
    }
  })
}

function isImageFile(key, value) {
  return key === 'file' && value.contentType.includes('image')
}

// ------- CUSTOM FORMATTING --------

/*
 *
 * Create an object const with the same name as a contentful content type. Any field that requires
 * custom formatting should appear as a key on the object pointing to the custom formatting function
 *
 */

// ------  AEDITION ARTICLES --------
const aeditionArticles = {
  relatedArticle: formatRelatedArticle,
  relatedArticlesMany: arr => arr.map(formatRelatedArticle).filter(Boolean),
  collection: formatCollection,
}

function formatRelatedArticle(relatedArticle) {
  const { fields, sys } = relatedArticle || {}

  if (!fields || !sys) {
    return null
  }

  const { title, collection, author, slug, articleImage, intro } = fields
  return formatFields({ id: sys.id, title, collection, author, slug, articleImage, intro })
}

function formatCollection(collection) {
  const { fields, sys } = collection || {}

  if (!fields || !sys) {
    return null
  }

  const { title, shortBlurb, blurb, slug } = fields
  return { id: sys.id, title, shortBlurb, blurb, slug }
}

// ------ AEDIT SOLUTIONS --------
const aeditSolutions = {
  relatedAeditionArticles: arr => arr.map(formatRelatedArticle).filter(Boolean),
  collection: formatCollection,
}

// -------- CONFIG ---------

/*
 *
 *  Add custom formatting to the CUSTOM_FORMATS object to include in recursive formatter
 *
 */

const CUSTOM_FORMATS = {
  aeditionArticles,
  aeditSolutions,
}

function getCustomFormat(contentType, key) {
  return CUSTOM_FORMATS[contentType]?.[key]
}

function hasCustomFormat(contentType, key) {
  return !!CUSTOM_FORMATS[contentType]?.[key]
}
