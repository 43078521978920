import React, { useRef, useEffect } from 'react'
import Carousel from 'nuka-carousel'
import Img from 'components/img/cloudinary-img'
import SensitiveContentBlur from 'components/sensitive_content_blur'
import { getBeforeAfterAltText } from 'services/util/image'
import { BEURO__Icon } from 'styleguide/packages/components'
import styles from './index.scss'

const ThumbnailCarousel = ({ photoAlbum, selectedImage, swapImage }) => {
  const baCarousal = useRef()

  const handleNext = e => {
    e.preventDefault()
    const nextIdx = selectedImage.index + 1

    if (nextIdx < photoAlbum.length) {
      baCarousal.current.nextSlide()
      swapImage(photoAlbum[nextIdx], nextIdx)
    }
  }

  const handlePrevious = e => {
    e.preventDefault()
    const prevIdx = selectedImage.index - 1

    if (prevIdx >= 0) {
      baCarousal.current.previousSlide()
      swapImage(photoAlbum[prevIdx], prevIdx)
    }
  }

  const pressedLeftArrow = e => {
    if (e.keyCode === 37) {
      handlePrevious(e)
    }
  }

  const pressedRightArrow = e => {
    if (e.keyCode === 39) {
      handleNext(e)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', pressedRightArrow)
    document.addEventListener('keydown', pressedLeftArrow)

    return () => {
      document.removeEventListener('keydown', pressedRightArrow, false)
      document.removeEventListener('keydown', pressedLeftArrow, false)
    }
  }, [selectedImage])

  return (
    <div className="carousel-container">
      <Carousel
        enableKeyboardControls
        ref={baCarousal}
        cellSpacing={3}
        slidesToShow={4}
        slideWidth="50px"
        slideHeight="50px"
        width="175px"
        renderCenterLeftControls={() => {}}
        renderCenterRightControls={() => {}}
        renderBottomCenterControls={() => {}}>
        {photoAlbum.map((photo, i) => (
          <div
            className={`carousel-image-wrapper ${
              photo.id === selectedImage.id ? '--selected' : ''
            }`}>
            <SensitiveContentBlur
              isExplicit={Boolean(Number(photo.meta.explicit))}
              onClick={() => swapImage(photo, i)}>
              <Img
                key={photo.id}
                publicId={photo.after_images[0].url}
                className="carousel-image"
                alt={getBeforeAfterAltText(photo, 'after')}
              />
            </SensitiveContentBlur>
          </div>
        ))}
      </Carousel>

      <div className="carousel-controls-wrapper">
        <BEURO__Icon
          name="arrow"
          width="20"
          className="controls --previous"
          onClick={e => handlePrevious(e)}
        />
        <p className="BEURO__overline counter">
          {selectedImage.index + 1}/{photoAlbum.length}
        </p>
        <BEURO__Icon
          name="arrow"
          width="20"
          className="controls --next"
          onClick={e => handleNext(e)}
        />
      </div>

      <style jsx>{styles}</style>
    </div>
  )
}

export default ThumbnailCarousel
