import { useContext } from 'react'
import { GlobalContext } from 'components/global'
import AeditLink from 'services/link'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { isProtectedRoute } from 'services/util/auth'
import { Toast } from 'styleguide/packages/components'

const LogoutLink = props => {
  const { callback, ...rest } = props
  const { updateUserSession } = useContext(GlobalContext)
  const { asPath: currentPath = '' } = useRouter()

  // they are on provider portal or user profile when logging out, must be redirected
  const redirectOnLogout = isProtectedRoute(currentPath)

  const onLogOut = async () => {
    try {
      // calling updateUserSession without args signs user out
      updateUserSession()

      const { unidentifyUser } = await import('services/flagsmith/user')
      await unidentifyUser()
    } finally {
      toast(<Toast.Success message="Signed Out" />)
      if (typeof callback === 'function') {
        callback()
      }
    }
  }

  return redirectOnLogout ? (
    <AeditLink {...rest} pathname="/log-out" />
  ) : (
    <span {...rest} onClick={onLogOut} />
  )
}

export default LogoutLink
