import { useEffect } from 'react'
import useMessageEvent from 'hooks/use_message_event'
import { redirectTo } from 'services/next'
import { trackError } from 'services/util/error'

/**
 * Returns a function that can be used to trigger the OAuth2 flow
 *
 * Flow:
 * 1. React component uses useOAuth() hook and invokes authenticate()
 * 2. authenticate() sets up new message receiver and opens loginUri in a popup
 * 3. server-side code on the loginUri page redirects the user to 3rd party provider
 * 4. user logs in and approves AEDIT access to their account
 * 5. 3rd party provider redirects the user back to {{loginUri}}?code={{code}}
 * 6. server-side code on the loginUri page exchanges the authorization code for an id token
 * 7. client-side code on the same page sends the token to the receiver (step 2) through useOAuthCallback
 * 8. promise returned from authenticate() (step 2) resolves with the user's id token
 *
 * @param {string} loginUri Absolute URL to the login page
 * @param {?string=} windowFeatures Additional params supplied to window.open
 * @returns {function} Trigger the authentication flow
 */
export const useOAuth = (loginUri, windowFeatures = null) => {
  if (!loginUri) {
    throw new Error('Missing required parameter loginUri')
  }

  const { receiveMessage } = useMessageEvent()

  /**
   * @returns {Promise<string>} Token
   */
  const authenticate = async () => {
    const messagePromise = receiveMessage(loginUri)
    const handle = window.open(loginUri, null, windowFeatures ?? 'width=486,height=690')

    try {
      // we're now waiting for sendMessage in useOAuthCallback to send us the user's id token
      const { err, token } = await messagePromise
      if (err) {
        throw new Error(err)
      } else {
        return token
      }
    } finally {
      handle.close()
    }
  }

  return authenticate
}

/**
 * @param {string} loginUri Absolute URL to the login page
 * @param {?string=} err Error returned from the login page
 * @param {?string=} token Token returned from the login page
 */
export const useOAuthCallback = (loginUri, err, token) => {
  if (!loginUri) {
    throw new Error('Missing required parameter loginUri')
  }

  const { sendMessage } = useMessageEvent()

  useEffect(() => {
    if (!window.opener) {
      // sign-in will not work without a main window that listens for posted messages
      redirectTo('/')
    }
  }, [])

  useEffect(() => {
    if (!err && !token) {
      return
    }

    if (!window.opener) {
      trackError(
        'useOAuthCallback: Assertion failed',
        new Error(`Window is not a popup but token is ${token ? '' : 'not '}set and err is ${err}`)
      )
      return
    }

    try {
      const payload = { err, token }
      sendMessage({ sender: loginUri, payload, target: window.opener })
      // the flow continues in useOAuth when you await the promise returned from receiveMessage
    } catch (e) {
      trackError('useOAuthCallback: Posting message failed', e)
    } finally {
      window.close()
      redirectTo('/') // don't stay on the current blank page if the window could not be closed
    }
  }, [err, token])
}
