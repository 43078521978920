import React from 'react'

// color variables
const $BEURO__black = '#000'
const $BEURO__white = '#fff'
const $BEURO__green = '#20685d'

const icons = {
  'try-on-face': (color = $BEURO__black) => (
    <>
      <path
        d="M13.3 7.5c0 1.392-.466 3.264-1.396 4.771C10.977 13.774 9.668 14.8 8 14.8c-1.535 0-2.847-1.015-3.816-2.537C3.22 10.747 2.7 8.876 2.7 7.5c0-1.295.637-2.625 1.656-3.644C5.375 2.837 6.705 2.2 8 2.2s2.626.637 3.644 1.656C12.663 4.875 13.3 6.205 13.3 7.5Z"
        stroke={color}
        stroke-width="1.4"
        fill="none"
      />
      <path
        d="M6.667 11s.796 1 1.5 1 1.5-1 1.5-1M.5 1.5h2.333M13.167 14.167H15.5M1.5 2.833V.5M14.167 15.5v-2.333"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>
  ),
  arrow: (color = $BEURO__black) => (
    <path
      fill={color}
      d="M24.833 9.626c-0.575-0.46-1.414-0.367-1.874 0.208s-0.367 1.414 0.208 1.874l3.699 2.959h-25.532c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333h25.532l-3.699 2.959c-0.575 0.46-0.668 1.299-0.208 1.874s1.299 0.668 1.874 0.208l6.657-5.326c0.029-0.023 0.056-0.046 0.083-0.071 0.031-0.029 0.060-0.059 0.088-0.090 0.217-0.243 0.339-0.558 0.339-0.888 0-0.426-0.199-0.805-0.51-1.049l-6.657-5.326z"></path>
  ),

  'arrow-in-circle': () => (
    <>
      <path d="M 12.800781 2.5 C 7.1238351 2.5 2.5 7.1238351 2.5 12.800781 C 2.5 18.477778 7.1238475 23.099609 12.800781 23.099609 C 18.477765 23.099609 23.099609 18.477765 23.099609 12.800781 C 23.099609 7.1238475 18.477778 2.5 12.800781 2.5 z M 12.800781 4.4570312 C 17.419891 4.4570313 21.142578 8.181721 21.142578 12.800781 C 21.142578 17.419903 17.419903 21.142578 12.800781 21.142578 C 8.181721 21.142578 4.4570312 17.419891 4.4570312 12.800781 C 4.4570313 8.1817334 8.1817334 4.4570313 12.800781 4.4570312 z " />
      <path d="M 12.820312 8.09375 A 0.97873503 0.97873503 0 0 0 12.109375 8.3789062 A 0.97873503 0.97873503 0 0 0 12.109375 9.7636719 L 15.144531 12.800781 L 12.109375 15.835938 A 0.97873503 0.97873503 0 0 0 12.109375 17.220703 A 0.97873503 0.97873503 0 0 0 13.492188 17.220703 L 17.220703 13.492188 A 0.9788329 0.9788329 0 0 0 17.220703 12.107422 L 13.492188 8.3789062 A 0.97873503 0.97873503 0 0 0 12.820312 8.09375 z " />
      <path d="M 9.0722656 11.822266 A 0.97873503 0.97873503 0 0 0 8.09375 12.800781 A 0.97873503 0.97873503 0 0 0 9.0722656 13.779297 L 16.529297 13.779297 A 0.97873503 0.97873503 0 0 0 17.507812 12.800781 A 0.97873503 0.97873503 0 0 0 16.529297 11.822266 L 9.0722656 11.822266 z " />
    </>
  ),

  award: (color = $BEURO__black) => (
    <>
      <path
        d="M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z"
        stroke={color}
        fill="transparent"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88"
        stroke={color}
        fill="transparent"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),

  calendar: () => (
    <>
      <path d="M10 12h4v4h-4zM16 12h4v4h-4zM22 12h4v4h-4zM4 24h4v4h-4zM10 24h4v4h-4zM16 24h4v4h-4zM10 18h4v4h-4zM16 18h4v4h-4zM22 18h4v4h-4zM4 18h4v4h-4zM26 0v2h-4v-2h-14v2h-4v-2h-4v32h30v-32h-4zM28 30h-26v-22h26v22z"></path>
    </>
  ),
  chat: () => (
    <>
      <path d="M 5.5332031 2.6992188 C 4.7820216 2.6992188 4.0604564 2.9981506 3.5292969 3.5292969 C 2.9981325 4.0604612 2.7011719 4.7820231 2.7011719 5.5332031 L 2.7011719 22.599609 A 0.70006999 0.70006999 0 0 0 3.8945312 23.095703 L 7.9570312 19.033203 L 20.466797 19.033203 C 21.217927 19.033203 21.939509 18.734319 22.470703 18.203125 C 23.001717 17.671979 23.300781 16.950432 23.300781 16.199219 L 23.300781 5.5332031 C 23.300781 4.7819901 23.001739 4.0604393 22.470703 3.5292969 C 21.93954 2.9981733 21.217985 2.6992187 20.466797 2.6992188 L 5.5332031 2.6992188 z M 5.5332031 4.0996094 L 20.466797 4.0996094 C 20.847209 4.0996094 21.211432 4.2505148 21.480469 4.5195312 C 21.749433 4.7885488 21.900391 5.1528161 21.900391 5.5332031 L 21.900391 16.199219 C 21.900391 16.579606 21.749455 16.943837 21.480469 17.212891 C 21.211463 17.481896 20.847266 17.632812 20.466797 17.632812 L 7.6679688 17.632812 A 0.70006999 0.70006999 0 0 0 7.171875 17.837891 L 4.0996094 20.912109 L 4.0996094 5.5332031 C 4.0996094 5.1527831 4.2505356 4.7885269 4.5195312 4.5195312 C 4.7885317 4.2505375 5.1528046 4.0996094 5.5332031 4.0996094 z " />
    </>
  ),

  checkmark: (color = $BEURO__black) => (
    <path
      d="M20 6L9 17L4 12"
      stroke={color}
      fill="none"
      strokeWidth="2"
      strokeMiterlimit="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),

  chevron: () => (
    <>
      <path d="M15.057 11.057c0.521-0.521 1.365-0.521 1.886 0l8 8c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-7.057-7.057-7.057 7.057c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l8-8z"></path>
    </>
  ),

  'chevron-down': () => (
    <>
      <path d="M7.057 11.057c0.521-0.521 1.365-0.521 1.886 0l7.057 7.057 7.057-7.057c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-8 8c-0.521 0.521-1.365 0.521-1.886 0l-8-8c-0.521-0.521-0.521-1.365 0-1.886z"></path>
    </>
  ),

  'chevron-left': () => (
    <>
      <path d="M20.943 7.057c0.521 0.521 0.521 1.365 0 1.886l-7.057 7.057 7.057 7.057c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-8-8c-0.521-0.521-0.521-1.365 0-1.886l8-8c0.521-0.521 1.365-0.521 1.886 0z"></path>
    </>
  ),

  'chevron-right': () => (
    <>
      <path d="M11.057 7.057c0.521-0.521 1.365-0.521 1.886 0l8 8c0.521 0.521 0.521 1.365 0 1.886l-8 8c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l7.057-7.057-7.057-7.057c-0.521-0.521-0.521-1.365 0-1.886z"></path>
    </>
  ),

  'chevron-up': () => (
    <>
      <path d="M15.057 11.057c0.521-0.521 1.365-0.521 1.886 0l8 8c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-7.057-7.057-7.057 7.057c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l8-8z"></path>
    </>
  ),

  close: (color = $BEURO__black) => (
    <>
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M24 8l-16 16"></path>
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M8 8l16 16"></path>
    </>
  ),

  compare: (color = $BEURO__black) => (
    <path
      stroke={color}
      d="M16.933 1.333v3.067h0.067c0.515 0 0.933 0.418 0.933 0.933s-0.418 0.933-0.933 0.933h-0.067v19.467h0.067c0.515 0 0.933 0.418 0.933 0.933s-0.418 0.933-0.933 0.933h-0.067v3.067c0 0.515-0.418 0.933-0.933 0.933s-0.933-0.418-0.933-0.933v-3.067h-11.067c-1.988 0-3.6-1.612-3.6-3.6v-16c0-1.988 1.612-3.6 3.6-3.6h11.067v-3.067c0-0.515 0.418-0.933 0.933-0.933s0.933 0.418 0.933 0.933zM15.066 6.266h-11.067c-0.957 0-1.733 0.776-1.733 1.733v16c0 0.957 0.776 1.733 1.733 1.733h11.067v-19.467zM28.931 4.522c0.498 0.133 0.794 0.645 0.661 1.143s-0.645 0.794-1.143 0.661c-0.142-0.038-0.293-0.059-0.449-0.059h-1c-0.515 0-0.933-0.418-0.933-0.933s0.418-0.933 0.933-0.933h1c0.321 0 0.633 0.042 0.931 0.122zM23.933 5.333c0 0.515-0.418 0.933-0.933 0.933h-2c-0.515 0-0.933-0.418-0.933-0.933s0.418-0.933 0.933-0.933h2c0.515 0 0.933 0.418 0.933 0.933zM30.335 6.408c0.498-0.133 1.009 0.163 1.143 0.661 0.080 0.298 0.122 0.611 0.122 0.931v1.333c0 0.515-0.418 0.933-0.933 0.933s-0.933-0.418-0.933-0.933v-1.333c0-0.157-0.021-0.307-0.059-0.449-0.133-0.498 0.163-1.010 0.661-1.143zM30.666 13.733c0.515 0 0.933 0.418 0.933 0.933v2.667c0 0.515-0.418 0.933-0.933 0.933s-0.933-0.418-0.933-0.933v-2.667c0-0.515 0.418-0.933 0.933-0.933zM30.666 21.733c0.515 0 0.933 0.418 0.933 0.933v1.333c0 0.321-0.042 0.633-0.122 0.931-0.133 0.498-0.645 0.794-1.143 0.661s-0.794-0.645-0.661-1.143c0.038-0.142 0.059-0.293 0.059-0.449v-1.333c0-0.515 0.418-0.933 0.933-0.933zM29.592 26.335c0.133 0.498-0.163 1.009-0.661 1.143-0.298 0.080-0.611 0.122-0.931 0.122h-1c-0.515 0-0.933-0.418-0.933-0.933s0.418-0.933 0.933-0.933h1c0.157 0 0.307-0.021 0.449-0.059 0.498-0.133 1.010 0.163 1.143 0.661zM23.933 26.666c0 0.515-0.418 0.933-0.933 0.933h-2c-0.515 0-0.933-0.418-0.933-0.933s0.418-0.933 0.933-0.933h2c0.515 0 0.933 0.418 0.933 0.933z"
    />
  ),

  dot: (color = $BEURO__black) => (
    <>
      <path
        d="m16,19c1.6569,0 3,-1.3431 3,-3c0,-1.65685 -1.3431,-3 -3,-3c-1.6569,0 -3,1.34315 -3,3c0,1.6569 1.3431,3 3,3z"
        stroke={color}
        fill="transparent"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),

  expertise: (color = $BEURO__black) => (
    <>
      <path
        d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z"
        stroke={color}
        fill="transparent"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.8311V10.8311"
        stroke={color}
        fill="transparent"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.8311H15"
        stroke={color}
        fill="transparent"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 7V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V7"
        stroke={color}
        fill="transparent"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),

  film: (color = $BEURO__black) => (
    <>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M26.426 2.667h-20.853c-1.605 0-2.907 1.301-2.907 2.907v20.853c0 1.605 1.301 2.907 2.907 2.907h20.853c1.605 0 2.907-1.301 2.907-2.907v-20.853c0-1.605-1.301-2.907-2.907-2.907z"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M9.334 2.667v26.667"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M22.666 2.667v26.667"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M2.666 16h26.667"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M2.666 9.333h6.667"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M2.666 22.667h6.667"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M22.666 22.667h6.667"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M22.666 9.333h6.667"></path>
    </>
  ),

  google: () => (
    <>
      <path
        fill="#4285f4"
        d="M31.36 16.364c0-1.135-0.102-2.225-0.291-3.273h-15.069v6.189h8.611c-0.371 2-1.498 3.695-3.193 4.829v4.014h5.171c3.025-2.785 4.771-6.887 4.771-11.76z"></path>
      <path
        fill="#34a853"
        d="M16 32c4.32 0 7.942-1.433 10.589-3.876l-5.171-4.015c-1.433 0.96-3.265 1.527-5.418 1.527-4.167 0-7.695-2.815-8.953-6.596h-5.345v4.145c2.633 5.229 8.044 8.815 14.298 8.815z"></path>
      <path
        fill="#fbbc05"
        d="M7.047 19.040c-0.32-0.96-0.502-1.985-0.502-3.040s0.182-2.080 0.502-3.040v-4.145h-5.345c-1.084 2.16-1.702 4.604-1.702 7.185s0.618 5.025 1.702 7.185l5.345-4.145z"></path>
      <path
        fill="#ea4335"
        d="M16 6.364c2.349 0 4.458 0.807 6.116 2.393l4.589-4.589c-2.771-2.582-6.393-4.167-10.706-4.167-6.255 0-11.665 3.585-14.298 8.815l5.345 4.145c1.258-3.782 4.785-6.596 8.953-6.596z"></path>
    </>
  ),

  'magnifying-glass': (color = $BEURO__black) => (
    <>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M14.667 25.333c5.891 0 10.667-4.776 10.667-10.667s-4.776-10.667-10.667-10.667c-5.891 0-10.667 4.776-10.667 10.667s4.776 10.667 10.667 10.667z"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M28.001 28l-5.8-5.8"></path>
    </>
  ),

  microphone: () => (
    <>
      <path d="M 12 0.30078125 C 11.019006 0.30078125 10.076526 0.68911117 9.3828125 1.3828125 C 8.6891178 2.0765072 8.3007812 3.0189678 8.3007812 4 L 8.3007812 12 C 8.3007812 12.980994 8.6891009 13.923488 9.3828125 14.617188 C 10.076517 15.310867 11.018989 15.699219 12 15.699219 C 12.981011 15.699219 13.923497 15.310878 14.617188 14.617188 C 15.310878 13.923497 15.699219 12.981011 15.699219 12 L 15.699219 4 C 15.699219 3.0189502 15.310861 2.076498 14.617188 1.3828125 C 13.923488 0.68910089 12.980994 0.30078125 12 0.30078125 z M 12 1.6992188 C 12.610206 1.6992188 13.195452 1.9415385 13.626953 2.3730469 C 14.05848 2.8045814 14.300781 3.3897498 14.300781 4 L 14.300781 12 C 14.300781 12.610189 14.058463 13.195443 13.626953 13.626953 C 13.195443 14.058463 12.610189 14.300781 12 14.300781 C 11.389811 14.300781 10.804582 14.058473 10.373047 13.626953 C 9.9415385 13.195452 9.6992188 12.610206 9.6992188 12 L 9.6992188 4 C 9.6992188 3.3897322 9.9415216 2.8045721 10.373047 2.3730469 C 10.804573 1.9415282 11.389794 1.6992188 12 1.6992188 z " />
      <path d="M 5 9.3007812 A 0.69999999 0.69999999 0 0 0 4.3007812 10 L 4.3007812 12 C 4.3007812 14.041885 5.1108381 16.001518 6.5546875 17.445312 C 7.9985344 18.889203 9.9581153 19.699219 12 19.699219 C 14.041885 19.699219 16.001517 18.889218 17.445312 17.445312 C 18.889218 16.001517 19.699219 14.041885 19.699219 12 L 19.699219 10 A 0.69999999 0.69999999 0 0 0 19 9.3007812 A 0.69999999 0.69999999 0 0 0 18.300781 10 L 18.300781 12 C 18.300781 13.671115 17.636773 15.273474 16.455078 16.455078 C 15.273474 17.636773 13.671115 18.300781 12 18.300781 C 10.328885 18.300781 8.726595 17.636787 7.5449219 16.455078 C 6.3632712 15.273472 5.6992188 13.671115 5.6992188 12 L 5.6992188 10 A 0.69999999 0.69999999 0 0 0 5 9.3007812 z " />
      <path d="M 12 18.300781 A 0.69999999 0.69999999 0 0 0 11.300781 19 L 11.300781 23 A 0.69999999 0.69999999 0 0 0 12 23.699219 A 0.69999999 0.69999999 0 0 0 12.699219 23 L 12.699219 19 A 0.69999999 0.69999999 0 0 0 12 18.300781 z " />
      <path d="M 8 22.300781 A 0.69999999 0.69999999 0 0 0 7.3007812 23 A 0.69999999 0.69999999 0 0 0 8 23.699219 L 16 23.699219 A 0.69999999 0.69999999 0 0 0 16.699219 23 A 0.69999999 0.69999999 0 0 0 16 22.300781 L 8 22.300781 z " />
    </>
  ),

  'microphone-off': () => (
    <>
      <path d="M 0.98632812 0.30078125 A 0.69999999 0.69999999 0 0 0 0.50585938 0.50585938 A 0.69999999 0.69999999 0 0 0 0.50585938 1.4941406 L 22.505859 23.494141 A 0.69999999 0.69999999 0 0 0 23.494141 23.494141 A 0.69999999 0.69999999 0 0 0 23.494141 22.505859 L 1.4941406 0.50585938 A 0.69999999 0.69999999 0 0 0 0.98632812 0.30078125 z " />
      <path d="M 12.371094 0.31640625 C 11.458373 0.2242155 10.543302 0.4734817 9.8046875 1.0175781 C 9.0660943 1.5616645 8.5556023 2.3607495 8.3730469 3.2597656 A 0.69999999 0.69999999 0 0 0 8.9199219 4.0859375 A 0.69999999 0.69999999 0 0 0 9.7460938 3.5390625 C 9.8596383 2.9798986 10.177352 2.4829249 10.636719 2.1445312 C 11.096104 1.8061277 11.66279 1.6516451 12.230469 1.7089844 C 12.798146 1.7663273 13.324536 2.0316103 13.707031 2.4550781 C 14.089421 2.8785142 14.301318 3.4293904 14.300781 4 L 14.300781 9.3398438 A 0.69999999 0.69999999 0 0 0 15 10.039062 A 0.69999999 0.69999999 0 0 0 15.699219 9.3398438 L 15.699219 4 C 15.700082 3.0826696 15.360904 2.198382 14.746094 1.5175781 C 14.131189 0.83680594 13.283817 0.40860335 12.371094 0.31640625 z M 8.9863281 8.3007812 A 0.70006999 0.70006999 0 0 0 8.3007812 9 L 8.3007812 12 C 8.3014226 12.731209 8.5172773 13.446859 8.9238281 14.054688 C 9.3304185 14.662575 9.9083997 15.13631 10.583984 15.416016 C 11.259643 15.695824 12.003439 15.769518 12.720703 15.626953 C 13.437965 15.484512 14.097838 15.132014 14.615234 14.615234 A 0.70006999 0.70006999 0 0 0 14.615234 13.625 L 9.4941406 8.5058594 A 0.70006999 0.70006999 0 0 0 8.9863281 8.3007812 z M 9.6992188 10.6875 L 12.980469 13.96875 C 12.798207 14.059677 12.649225 14.213799 12.447266 14.253906 C 12.00133 14.342541 11.541236 14.297039 11.121094 14.123047 A 0.70006999 0.70006999 0 0 0 11.119141 14.123047 C 10.699125 13.949152 10.34066 13.653303 10.087891 13.275391 C 9.8350815 12.897419 9.6996174 12.454591 9.6992188 12 L 9.6992188 10.6875 z " />
      <path d="M 19 9.3007812 A 0.69999999 0.69999999 0 0 0 18.300781 10 L 18.300781 12 C 18.300421 12.371209 18.266699 12.741984 18.201172 13.107422 A 0.69999999 0.69999999 0 0 0 18.767578 13.919922 A 0.69999999 0.69999999 0 0 0 19.580078 13.353516 C 19.660151 12.906954 19.700732 12.453591 19.701172 12 L 19.701172 10 A 0.69999999 0.69999999 0 0 0 19 9.3007812 z M 5.0058594 11.300781 A 0.69999999 0.69999999 0 0 0 4.3007812 11.994141 C 4.2895507 13.528467 4.7372469 15.032215 5.5859375 16.310547 C 6.4346575 17.588813 7.6460777 18.584766 9.0644531 19.169922 C 10.482873 19.754991 12.043802 19.901844 13.546875 19.59375 A 0.69999999 0.69999999 0 0 0 14.091797 18.767578 A 0.69999999 0.69999999 0 0 0 13.265625 18.222656 C 12.035698 18.474762 10.758276 18.353731 9.5976562 17.875 C 8.4369716 17.396156 7.4464131 16.58109 6.7519531 15.535156 C 6.0574637 14.489088 5.6900293 13.261333 5.6992188 12.005859 A 0.69999999 0.69999999 0 0 0 5.0058594 11.300781 z " />
      <path d="M 12 18.300781 A 0.69999999 0.69999999 0 0 0 11.300781 19 L 11.300781 23 A 0.69999999 0.69999999 0 0 0 12 23.699219 A 0.69999999 0.69999999 0 0 0 12.699219 23 L 12.699219 19 A 0.69999999 0.69999999 0 0 0 12 18.300781 z " />
      <path d="M 8 22.300781 A 0.69999999 0.69999999 0 0 0 7.3007812 23 A 0.69999999 0.69999999 0 0 0 8 23.699219 L 16 23.699219 A 0.69999999 0.69999999 0 0 0 16.699219 23 A 0.69999999 0.69999999 0 0 0 16 22.300781 L 8 22.300781 z " />
    </>
  ),

  move: (color = $BEURO__black) => (
    <>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M6.667 12l-4 4 4 4"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M12 6.667l4-4 4 4"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M20 25.333l-4 4-4-4"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M25.333 12l4 4-4 4"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M2.667 16h26.667"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M16 2.667v26.667"></path>
    </>
  ),

  notification: () => (
    <>
      <path d="M 12 1.3007812 C 10.223318 1.3007812 8.5180235 2.005414 7.2617188 3.2617188 C 6.005414 4.5180235 5.3007813 6.2233178 5.3007812 8 C 5.3007812 11.4 4.5749854 13.52366 3.8847656 14.789062 C 3.1945459 16.054465 2.6113281 16.417969 2.6113281 16.417969 A 0.70006999 0.70006999 0 0 0 3 17.699219 L 21 17.699219 A 0.70006999 0.70006999 0 0 0 21.388672 16.417969 C 21.388672 16.417969 20.805454 16.054465 20.115234 14.789062 C 19.425015 13.52366 18.699219 11.4 18.699219 8 C 18.699219 6.2233296 17.994683 4.5180313 16.738281 3.2617188 C 15.481997 2.0054118 13.776682 1.3007812 12 1.3007812 z M 12 2.6992188 C 13.405918 2.6992188 14.753931 3.2578201 15.748047 4.2519531 C 16.742245 5.2460806 17.300781 6.5940704 17.300781 8 C 17.300781 11.6 18.074985 13.97634 18.884766 15.460938 C 19.20539 16.048749 19.277795 15.965529 19.568359 16.300781 L 4.4316406 16.300781 C 4.7222052 15.965529 4.7946099 16.048749 5.1152344 15.460938 C 5.9250146 13.97634 6.6992188 11.6 6.6992188 8 C 6.6992188 6.5940822 7.2578179 5.2460884 8.2519531 4.2519531 C 9.2460884 3.2578179 10.594082 2.6992188 12 2.6992188 z " />
      <path d="M 10.228516 20.300781 A 0.69999999 0.69999999 0 0 0 9.9179688 20.394531 A 0.69999999 0.69999999 0 0 0 9.6640625 21.351562 C 9.9014048 21.760769 10.242417 22.099919 10.652344 22.335938 C 11.062135 22.572013 11.52694 22.697266 12 22.697266 C 12.47306 22.697266 12.937865 22.572013 13.347656 22.335938 C 13.757583 22.099919 14.098595 21.760769 14.335938 21.351562 A 0.69999999 0.69999999 0 0 0 14.082031 20.394531 A 0.69999999 0.69999999 0 0 0 13.125 20.648438 C 13.010742 20.845431 12.845711 21.009465 12.648438 21.123047 C 12.451029 21.236771 12.22774 21.296875 12 21.296875 C 11.77226 21.296875 11.548971 21.236771 11.351562 21.123047 C 11.154289 21.009465 10.989258 20.845431 10.875 20.648438 A 0.69999999 0.69999999 0 0 0 10.228516 20.300781 z " />
    </>
  ),

  pencil: (color = $BEURO__black) => (
    <>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.3333"
        d="M16 26.667h12"></path>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.3333"
        d="M22 4.667c0.53-0.53 1.25-0.828 2-0.828 0.371 0 0.739 0.073 1.082 0.215s0.655 0.35 0.918 0.613c0.263 0.263 0.471 0.574 0.613 0.918s0.215 0.711 0.215 1.082-0.073 0.739-0.215 1.082c-0.142 0.343-0.35 0.655-0.613 0.918l-16.667 16.667-5.333 1.333 1.333-5.333 16.667-16.667z"></path>
    </>
  ),

  phone: (color = $BEURO__black) => (
    <path
      d="M21.9999 16.92V19.92C22.0011 20.1985 21.944 20.4741 21.8324 20.7293C21.7209 20.9845 21.5572 21.2136 21.352 21.4018C21.1468 21.5901 20.9045 21.7335 20.6407 21.8227C20.3769 21.9119 20.0973 21.945 19.8199 21.92C16.7428 21.5856 13.7869 20.5341 11.1899 18.85C8.77376 17.3146 6.72527 15.2661 5.18993 12.85C3.49991 10.2412 2.44818 7.27097 2.11993 4.17997C2.09494 3.90344 2.12781 3.62474 2.21643 3.3616C2.30506 3.09846 2.4475 2.85666 2.6347 2.6516C2.82189 2.44653 3.04974 2.28268 3.30372 2.1705C3.55771 2.05831 3.83227 2.00024 4.10993 1.99997H7.10993C7.59524 1.9952 8.06572 2.16705 8.43369 2.48351C8.80166 2.79996 9.04201 3.23942 9.10993 3.71997C9.23656 4.68004 9.47138 5.6227 9.80993 6.52997C9.94448 6.8879 9.9736 7.27689 9.89384 7.65086C9.81408 8.02482 9.6288 8.36809 9.35993 8.63998L8.08993 9.90997C9.51349 12.4135 11.5864 14.4864 14.0899 15.91L15.3599 14.64C15.6318 14.3711 15.9751 14.1858 16.3491 14.1061C16.723 14.0263 17.112 14.0554 17.4699 14.19C18.3772 14.5285 19.3199 14.7634 20.2799 14.89C20.7657 14.9585 21.2093 15.2032 21.5265 15.5775C21.8436 15.9518 22.0121 16.4296 21.9999 16.92Z"
      stroke={color}
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeMiterlimit="4"
      strokeWidth="2"
      fill="none"
    />
  ),

  pin: (color = $BEURO__black) => (
    <>
      <path
        stroke={color}
        fill="transparent"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M28 13.333c0 9.333-12 17.333-12 17.333s-12-8-12-17.333c0-3.183 1.264-6.235 3.515-8.485s5.303-3.515 8.485-3.515c3.183 0 6.235 1.264 8.485 3.515s3.515 5.303 3.515 8.485z"></path>
      <path
        stroke={color}
        fill="transparent"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M16 17.333c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4c0 2.209 1.791 4 4 4z"></path>
    </>
  ),

  procedure: (color = $BEURO__black) => (
    <>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M21.333 5.333h2.667c0.707 0 1.385 0.281 1.886 0.781s0.781 1.178 0.781 1.886v18.667c0 0.707-0.281 1.385-0.781 1.886s-1.178 0.781-1.886 0.781h-16c-0.707 0-1.386-0.281-1.886-0.781s-0.781-1.178-0.781-1.886v-18.667c0-0.707 0.281-1.386 0.781-1.886s1.178-0.781 1.886-0.781h2.667"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M20 2.667h-8c-0.736 0-1.333 0.597-1.333 1.333v2.667c0 0.736 0.597 1.333 1.333 1.333h8c0.736 0 1.333-0.597 1.333-1.333v-2.667c0-0.736-0.597-1.333-1.333-1.333z"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M16 21.333v-8"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M12 17.333h8"></path>
    </>
  ),

  'thumbs-up': (color = $BEURO__black) => (
    <path
      d="M7 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V13C2 12.4696 2.21071 11.9609 2.58579 11.5858C2.96086 11.2107 3.46957 11 4 11H7M14 9V5C14 4.20435 13.6839 3.44129 13.1213 2.87868C12.5587 2.31607 11.7956 2 11 2L7 11V22H18.28C18.7623 22.0055 19.2304 21.8364 19.5979 21.524C19.9654 21.2116 20.2077 20.7769 20.28 20.3L21.66 11.3C21.7035 11.0134 21.6842 10.7207 21.6033 10.4423C21.5225 10.1638 21.3821 9.90629 21.1919 9.68751C21.0016 9.46873 20.7661 9.29393 20.5016 9.17522C20.2371 9.0565 19.9499 8.99672 19.66 9H14Z"
      stroke={color}
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeWidth="2"
      fill="none"
    />
  ),

  video: (color = $BEURO__black) => (
    <>
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M30.667 9.333l-9.333 6.667 9.333 6.667v-13.333z"
        fill="none"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M18.667 6.667h-14.667c-1.473 0-2.667 1.194-2.667 2.667v13.333c0 1.473 1.194 2.667 2.667 2.667h14.667c1.473 0 2.667-1.194 2.667-2.667v-13.333c0-1.473-1.194-2.667-2.667-2.667z"
        fill="none"
      />
    </>
  ),

  'video-off': () => (
    <>
      <path d="M 3 4.3007812 C 2.2850731 4.3007812 1.5977779 4.5848417 1.0917969 5.0898438 C 0.58562382 5.5960033 0.30078125 6.2841896 0.30078125 7 L 0.30078125 17 C 0.30078125 17.715791 0.58369823 18.402031 1.0898438 18.908203 C 1.5960092 19.414409 2.2841984 19.699219 3 19.699219 L 14 19.699219 C 14.715782 19.699219 15.402025 19.414381 15.908203 18.908203 C 16.414381 18.402025 16.699219 17.715782 16.699219 17 L 16.699219 16 A 0.70006999 0.70006999 0 0 0 16.494141 15.505859 L 5.4941406 4.5058594 A 0.70006999 0.70006999 0 0 0 5 4.3007812 L 3 4.3007812 z M 10.660156 4.3007812 A 0.69999999 0.69999999 0 0 0 9.9609375 5 A 0.69999999 0.69999999 0 0 0 10.660156 5.6992188 L 14 5.6992188 C 14.345009 5.6992188 14.675894 5.8360636 14.919922 6.0800781 C 15.163916 6.3240527 15.300781 6.6549416 15.300781 7 L 15.300781 10.339844 A 0.70006999 0.70006999 0 0 0 15.505859 10.835938 L 16.505859 11.835938 A 0.70006999 0.70006999 0 0 0 17.410156 11.90625 L 22.300781 8.3691406 L 22.300781 17 A 0.69999999 0.69999999 0 0 0 23 17.699219 A 0.69999999 0.69999999 0 0 0 23.699219 17 L 23.699219 7 A 0.70006999 0.70006999 0 0 0 22.589844 6.4335938 L 17.072266 10.423828 L 16.699219 10.050781 L 16.699219 7 C 16.699219 6.2841984 16.414409 5.5960092 15.908203 5.0898438 C 15.402031 4.5836982 14.715791 4.3007812 14 4.3007812 L 10.660156 4.3007812 z M 3 5.6992188 L 4.7109375 5.6992188 L 15.300781 16.289062 L 15.300781 17 C 15.300781 17.345018 15.163944 17.6759 14.919922 17.919922 C 14.6759 18.163944 14.345018 18.300781 14 18.300781 L 3 18.300781 C 2.6549416 18.300781 2.3240527 18.163916 2.0800781 17.919922 C 1.8360636 17.675894 1.6992187 17.345009 1.6992188 17 L 1.6992188 7 C 1.6992188 6.6549504 1.8360912 6.3240586 2.0800781 6.0800781 C 2.3240586 5.8360912 2.6549504 5.6992188 3 5.6992188 z " />
      <path d="M 0.98632812 0.30078125 A 0.69999999 0.69999999 0 0 0 0.50585938 0.50585938 A 0.69999999 0.69999999 0 0 0 0.50585938 1.4941406 L 22.505859 23.494141 A 0.69999999 0.69999999 0 0 0 23.494141 23.494141 A 0.69999999 0.69999999 0 0 0 23.494141 22.505859 L 1.4941406 0.50585938 A 0.69999999 0.69999999 0 0 0 0.98632812 0.30078125 z " />
    </>
  ),

  'zoom-in': (color = $BEURO__black) => (
    <>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M14.667 25.333c5.891 0 10.667-4.776 10.667-10.667s-4.776-10.667-10.667-10.667c-5.891 0-10.667 4.776-10.667 10.667s4.776 10.667 10.667 10.667z"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M28 28l-5.8-5.8"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M14.667 10.667v8"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M10.667 14.667h8"></path>
    </>
  ),

  'zoom-out': (color = $BEURO__black) => (
    <>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M14.667 25.333c5.891 0 10.667-4.776 10.667-10.667s-4.776-10.667-10.667-10.667c-5.891 0-10.667 4.776-10.667 10.667s4.776 10.667 10.667 10.667z"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M28 28l-5.8-5.8"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M10.667 14.667h8"></path>
    </>
  ),

  'thumbs-up': (color = $BEURO__black) => (
    <>
      <circle cx="24" cy="24" r="23.5" stroke={color} fill="none" />
      <path
        d="M19 33H16C15.4696 33 14.9609 32.7893 14.5858 32.4142C14.2107 32.0391 14 31.5304 14 31V24C14 23.4696 14.2107 22.9609 14.5858 22.5858C14.9609 22.2107 15.4696 22 16 22H19M26 20V16C26 15.2044 25.6839 14.4413 25.1213 13.8787C24.5587 13.3161 23.7956 13 23 13L19 22V33H30.28C30.7623 33.0055 31.2304 32.8364 31.5979 32.524C31.9654 32.2116 32.2077 31.7769 32.28 31.3L33.66 22.3C33.7035 22.0134 33.6842 21.7207 33.6033 21.4423C33.5225 21.1638 33.3821 20.9063 33.1919 20.6875C33.0016 20.4687 32.7661 20.2939 32.5016 20.1752C32.2371 20.0565 31.9499 19.9967 31.66 20H26Z"
        fill="none"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  'thumbs-down': (color = $BEURO__black) => (
    <>
      <circle cx="24" cy="24" r="23.5" stroke={color} fill="none" />
      <path
        d="M28.6826 15L31.6826 15C32.2131 15 32.7218 15.2107 33.0968 15.5858C33.4719 15.9609 33.6826 16.4696 33.6826 17L33.6826 24C33.6826 24.5304 33.4719 25.0391 33.0968 25.4142C32.7218 25.7893 32.2131 26 31.6826 26L28.6826 26M21.6826 28L21.6826 32C21.6826 32.7956 21.9987 33.5587 22.5613 34.1213C23.1239 34.6839 23.887 35 24.6826 35L28.6826 26L28.6826 15L17.4026 15C16.9203 14.9945 16.4522 15.1636 16.0847 15.476C15.7172 15.7884 15.4749 16.2231 15.4026 16.7L14.0226 25.7C13.9791 25.9866 13.9984 26.2793 14.0793 26.5577C14.1601 26.8362 14.3005 27.0937 14.4908 27.3125C14.681 27.5313 14.9165 27.7061 15.181 27.8248C15.4456 27.9435 15.7327 28.0033 16.0226 28L21.6826 28Z"
        fill="none"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),

  pinterest: () => (
    <>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="white"
        strokeWidth="1"
      />
      <mask id="path-2-inside-1" fill="white">
        <path d="M8.38722 18.4445C8.18969 19.3087 8.26376 20.5268 8.3543 21.3169L8.50039 22.0001L9.02097 21.5721C9.43249 20.9054 10.0415 19.819 10.2638 18.9466L10.8893 16.568C11.2103 17.1935 12.165 17.7202 13.1773 17.7202C16.1897 17.7202 18.3625 14.9466 18.3625 11.498C18.3625 8.18935 15.6629 5.72021 12.1979 5.72021C7.87693 5.72021 5.58887 8.61733 5.58887 11.7696C5.58887 13.2428 6.37076 15.0618 7.62179 15.6461C7.81109 15.7367 7.91809 15.6955 7.95924 15.5145C7.99216 15.3745 8.165 14.6914 8.23907 14.3787C8.26376 14.2799 8.25553 14.1894 8.17323 14.0906C7.76171 13.5885 7.43249 12.6667 7.43249 11.8107C7.43249 9.60499 9.10327 7.4733 11.951 7.4733C14.4119 7.4733 16.1321 9.14408 16.1321 11.5391C16.1321 14.2387 14.7658 16.1153 12.988 16.1153C12.0086 16.1153 11.2679 15.3005 11.5066 14.3046C11.7946 13.1112 12.3378 11.8272 12.3378 10.9712C12.3378 10.2058 11.9263 9.56384 11.0703 9.56384C10.0662 9.56384 9.25965 10.6009 9.25965 11.9918C9.25965 12.8725 9.55595 13.4733 9.55595 13.4733C9.55595 13.4733 8.56829 17.6626 8.38722 18.4445Z" />
      </mask>
      <path
        d="M8.38722 18.4445C8.18969 19.3087 8.26376 20.5268 8.3543 21.3169L8.50039 22.0001L9.02097 21.5721C9.43249 20.9054 10.0415 19.819 10.2638 18.9466L10.8893 16.568C11.2103 17.1935 12.165 17.7202 13.1773 17.7202C16.1897 17.7202 18.3625 14.9466 18.3625 11.498C18.3625 8.18935 15.6629 5.72021 12.1979 5.72021C7.87693 5.72021 5.58887 8.61733 5.58887 11.7696C5.58887 13.2428 6.37076 15.0618 7.62179 15.6461C7.81109 15.7367 7.91809 15.6955 7.95924 15.5145C7.99216 15.3745 8.165 14.6914 8.23907 14.3787C8.26376 14.2799 8.25553 14.1894 8.17323 14.0906C7.76171 13.5885 7.43249 12.6667 7.43249 11.8107C7.43249 9.60499 9.10327 7.4733 11.951 7.4733C14.4119 7.4733 16.1321 9.14408 16.1321 11.5391C16.1321 14.2387 14.7658 16.1153 12.988 16.1153C12.0086 16.1153 11.2679 15.3005 11.5066 14.3046C11.7946 13.1112 12.3378 11.8272 12.3378 10.9712C12.3378 10.2058 11.9263 9.56384 11.0703 9.56384C10.0662 9.56384 9.25965 10.6009 9.25965 11.9918C9.25965 12.8725 9.55595 13.4733 9.55595 13.4733C9.55595 13.4733 8.56829 17.6626 8.38722 18.4445Z"
        fill="white"
        stroke="white"
        strokeWidth=".1"
        mask="url(#path-2-inside-1)"
      />
    </>
  ),

  instagram: () => (
    <>
      <path d="M16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zM16 0c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.050-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1v0z"></path>
      <path d="M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zM16 21.331c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331c2.944 0 5.331 2.387 5.331 5.331s-2.387 5.331-5.331 5.331z"></path>
      <path d="M26.462 7.456c0 1.060-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919c0-1.060 0.859-1.919 1.919-1.919s1.919 0.859 1.919 1.919z"></path>
    </>
  ),

  linkedin: () => (
    <>
      <path d="M12 12h5.535v2.837h0.079c0.77-1.381 2.655-2.837 5.464-2.837 5.842 0 6.922 3.637 6.922 8.367v9.633h-5.769v-8.54c0-2.037-0.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509v8.688h-5.767v-18z"></path>
      <path d="M2 12h6v18h-6v-18z"></path>
      <path d="M8 7c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"></path>
    </>
  ),
  twitter: () => (
    <>
      <path d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z"></path>
    </>
  ),

  facebook: () => (
    <path d="M19 6h5v-6h-5c-3.86 0-7 3.14-7 7v3h-4v6h4v16h6v-16h5l1-6h-6v-3c0-0.542 0.458-1 1-1z"></path>
  ),
  bookmarks: (color = $BEURO__black) => (
    <path
      stroke={color}
      fill="transparent"
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeMiterlimit="4"
      strokeWidth="1.8667"
      d="M25.333 28l-9.333-6.667-9.333 6.667v-21.333c0-0.707 0.281-1.386 0.781-1.886s1.178-0.781 1.886-0.781h13.333c0.707 0 1.385 0.281 1.886 0.781s0.781 1.178 0.781 1.886v21.333z"></path>
  ),

  download: (color = $BEURO__black) => (
    <>
      <path
        d="M28 20v5.333c0 0.707-0.281 1.385-0.781 1.886s-1.178 0.781-1.886 0.781h-18.667c-0.707 0-1.386-0.281-1.886-0.781s-0.781-1.178-0.781-1.886v-5.333"
        fill="none"
        stroke={color}
        strokeWidth="1.8667"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
      />
      <path
        d="M9.333 13.333l6.667 6.667 6.667-6.667"
        fill="none"
        stroke={color}
        strokeWidth="1.8667"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
      />
      <path
        d="M16 20v-16"
        fill="none"
        stroke={color}
        strokeWidth="1.8667"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
      />
    </>
  ),

  camera: (color = $BEURO__black) => (
    <>
      <path
        d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
        stroke={color}
        fill="none"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
        stroke={color}
        fill="none"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),

  star: (color = $BEURO__green) => (
    <path
      fill="none"
      stroke={color}
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeMiterlimit="4"
      strokeWidth="1.8667"
      d="M16 2.667l4.12 8.347 9.213 1.347-6.667 6.493 1.573 9.173-8.24-4.333-8.24 4.333 1.573-9.173-6.667-6.493 9.213-1.347 4.12-8.347z"></path>
  ),
  clock: (color = $BEURO__black) => (
    <>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M16 29.333c7.364 0 13.333-5.97 13.333-13.333s-5.97-13.333-13.333-13.333c-7.364 0-13.333 5.97-13.333 13.333s5.97 13.333 13.333 13.333z"></path>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M16 8v8l5.333 2.667"></path>
    </>
  ),

  user: (color = $BEURO__black) => (
    <>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M26.667 28v-2.667c0-1.415-0.562-2.771-1.562-3.771s-2.357-1.562-3.771-1.562h-10.667c-1.415 0-2.771 0.562-3.771 1.562s-1.562 2.357-1.562 3.771v2.667"></path>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M16 14.667c2.945 0 5.333-2.388 5.333-5.333s-2.388-5.333-5.333-5.333c-2.946 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333z"></path>
    </>
  ),

  plus: (color = $BEURO__black) => (
    <>
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M16 6.667v18.667"></path>
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.8667"
        d="M6.667 16h18.667"></path>
    </>
  ),

  minus: (color = $BEURO__black) => (
    <path
      stroke={color}
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeMiterlimit="4"
      strokeWidth="1.8667"
      d="M6.667 16h18.667"
    />
  ),
  launch: (color = $BEURO__black) => (
    <>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.792"
        d="M23.040 17.317v7.68c0 0.679-0.27 1.33-0.75 1.81s-1.131 0.75-1.81 0.75h-14.080c-0.679 0-1.33-0.27-1.81-0.75s-0.75-1.131-0.75-1.81v-14.080c0-0.679 0.27-1.33 0.75-1.81s1.131-0.75 1.81-0.75h7.68"></path>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.792"
        d="M19.2 4.518h7.68v7.68"></path>
      <path
        stroke={color}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="1.792"
        d="M12.8 18.598l14.080-14.080"></path>
    </>
  ),
}

export default icons
