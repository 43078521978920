import React, { useState, useEffect, useRef } from 'react'
import { useTargetedScroll } from 'styleguide/packages/hooks/index.js'
import { Btn, Spinner } from 'styleguide/packages/components/index.js'

const InfiniteScroll = props => {
  const {
    children,
    initialState = [],
    data = [],
    currentPage,
    totalPages,
    loadMore,
    loadOnScroll = false,
    className = '',
  } = props
  const [scrollData, setScrollData] = useState(initialState)
  const [hasMore, setHasMore] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const loadingRef = useRef()

  const scrollLoadMore = async () => {
    const isHasMore = Boolean(currentPage + 1 < totalPages)
    setHasMore(isHasMore)

    if (hasMore) {
      setIsFetching(true)
      await loadMore(currentPage + 1)
      setIsFetching(false)
    }
  }

  useEffect(() => {
    setScrollData(data)
  }, [data])

  if (loadOnScroll) {
    useTargetedScroll({
      effect: scrollLoadMore,
      targetRef: loadingRef,
      deps: [currentPage],
    })
  }

  return (
    <div className={`infinite-scroll-container ${className}`}>
      <div id="infinite-scroll-grid" className={`infinite-scroll-grid`}>
        {scrollData.map((item, idx) => React.cloneElement(children, { item, idx }))}

        {hasMore && loadOnScroll && (
          <div className="spinner-wrapper" ref={loadingRef}>
            <Spinner />
          </div>
        )}
      </div>

      {hasMore && !loadOnScroll && (
        <Btn.Dark
          type="button"
          content="Load more"
          onClick={scrollLoadMore}
          isPending={isFetching}
        />
      )}
    </div>
  )
}

export default InfiniteScroll
