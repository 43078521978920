import React, { useState, useEffect } from 'react'
import algoliasearch from 'algoliasearch'
import { createNullCache } from '@algolia/cache-common'
import { toast } from 'react-toastify'
import { getAlgoliaProviderResults } from 'services/algolia'
import { Track } from 'services/analytics'
import { fullTitle } from 'services/util/provider'
import AeditLink from 'services/link'
import BAPhotoAlbum from '../ba_photo_album'
import {
  BEURO__Btn,
  BEURO__Icon,
  Portal,
  InfiniteScroll,
  Toast,
} from 'styleguide/packages/components'
import { isValidPhotoAlbum } from '../_util'
import { toggleOpen } from 'styleguide/packages/util/index.js'
import { showPortal } from 'components/portal'
import BAPrePaywallModal from '../ba_modal_learn_more'
import styles from './index.scss'
import { log } from 'services/util/log'

const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY, {
  responsesCache: createNullCache(),
  requestsCache: createNullCache(),
})

const algoliaIndex = client.initIndex(process.env.ALGOLIA_BEFORE_AFTER_COVERS_INDEX)

const BAAlbumCover = props => {
  const { item, setProvider, procedureName, setSelectedProcedure, baModalOpen, analytics } = props

  const openProviderModal = () => {
    if (baModalOpen) {
      setProvider(item.provider)
      setSelectedProcedure(item.procedure)
      toggleOpen('beuro-ba-modal--provider')
    } else {
      const paywallProps = { analytics }
      showPortal(BAPrePaywallModal, paywallProps)
    }
  }

  return (
    <div className="album-wrapper" onClick={openProviderModal}>
      <div className={`album-wrapper ${!baModalOpen ? '--blurred' : ''}`} key={item.id}>
        <BAPhotoAlbum albumCover={item} albumLength={item.length} procedureName={procedureName} />
        <div className="mobile-provider-info">
          <p className="t-body-sm">{fullTitle(item.provider)}</p>
          <p className="t-overline-sm pt-1">{item.procedure.name}</p>
          <span className="location-wrapper flex gap-1">
            <BEURO__Icon name="pin" width="20" />
            <p className="t-overline-sm">
              {item.provider.primary_practice?.location?.city},{' '}
              {item.provider.primary_practice?.location?.state_abbreviation}
            </p>
          </span>
        </div>
      </div>
    </div>
  )
}

const FindProviderBtn = ({ name, slug, className }) => {
  return (
    <AeditLink
      className={className}
      pathname="/p-[slug]-providers"
      as={`/p-${slug}-providers`}
      params={{ procedure: name }}>
      <BEURO__Btn.Gold content="Find a provider" />
    </AeditLink>
  )
}

const BAGalleryModal = ({
  title = null,
  geoLocationObj,
  algoliaBAData,
  setProvider,
  procedureName,
  baModalOpen,
  setSelectedProcedure,
  procedureTag,
  analytics,
  ...procedure
}) => {
  const { photoAlbums, totalPages } = algoliaBAData
  let [currentPage, setCurrentPage] = useState(0)
  let [albums, setAlbums] = useState(photoAlbums)

  const trackAlbumsLoaded = albumArray => {
    albumArray.forEach(album => {
      const trackData = {
        procedure_id: album.procedure.id,
        procedure_name: album.procedure.name,
        provider_id: album.provider.id,
        provider_name: album.provider.first_name + ' ' + album.provider.last_name,
      }

      Track.providerAlbumsLoaded(trackData)
    })
  }

  const fetchPhotoAlbums = async page => {
    try {
      let query = {
        page: page,
        // If child, fetch all the images associated with current procedure
        // If parent, fetch all the images associated with current procedure AND its children
        filters: procedure.is_parent
          ? `procedure.id = ${procedure.id} OR procedure.parent_id = ${procedure.id}`
          : `procedure.id = ${procedure.id}`,
      }
      const albumRes = await getAlgoliaProviderResults(algoliaIndex, geoLocationObj, '', query)

      const albumData = (albumRes.hits ?? []).filter(isValidPhotoAlbum)
      const newCurrentPage = albumRes.page

      trackAlbumsLoaded(albumData)

      setAlbums([...albums, ...albumData])
      setCurrentPage(newCurrentPage)
    } catch (e) {
      log('Failed to fetch photo albums', null, {
        error: e,
        index: process.env.ALGOLIA_BEFORE_AFTER_COVERS_INDEX,
      })
      toast(<Toast.Error message={e.message} />)
    }
  }

  useEffect(() => {
    if (baModalOpen) {
      trackAlbumsLoaded(photoAlbums)
    }
  }, [baModalOpen])

  const customModalHeader = title ? (
    <>
      <h3 className="t-subsection-header">{title}</h3>
      <FindProviderBtn className="hidden-mobile" name={procedure.name} slug={procedure.slug} />
    </>
  ) : (
    <>
      <span>
        <h3 className="t-subsection-header hidden-mobile mr-5">
          Before & After Images by Provider
        </h3>
        <p className="t-body procedure-name">{procedureName}</p>
      </span>
      <FindProviderBtn className="hidden-mobile" name={procedure.name} slug={procedure.slug} />
    </>
  )

  return (
    <>
      <Portal.Full id="beuro-ba-modal--gallery" customHeader={customModalHeader}>
        <div className="ba-album-wrapper">
          <p className="t-overline mobile-title">Images By Provider</p>

          <InfiniteScroll
            initialState={photoAlbums}
            data={albums}
            currentPage={currentPage}
            totalPages={totalPages}
            loadMore={fetchPhotoAlbums}
            loadOnScroll={true}>
            <BAAlbumCover
              procedureName={procedureName}
              setProvider={setProvider}
              setSelectedProcedure={setSelectedProcedure}
              procedureTag={procedureTag}
              baModalOpen={baModalOpen}
              analytics={analytics}
            />
          </InfiniteScroll>
        </div>

        <FindProviderBtn
          className="mobile-find-provider"
          name={procedure.name}
          slug={procedure.slug}
        />
      </Portal.Full>
      <style jsx>{styles}</style>
    </>
  )
}

export default BAGalleryModal
