import React, { useEffect, useState } from 'react'
import { getBeforeAfterAltText } from 'services/util/image'
import SelectedImageDescription from './components/selected_image_description'
import SensitiveContentBlur from 'components/sensitive_content_blur'
import Img from 'components/img/cloudinary-img'
import { fetchPhotoSets } from 'features/providers/profile/content/before_after_gallery/_helpers/index.js'
import useWindowSize from 'hooks/use_window_size'
import { Track } from 'services/analytics'
import { callApi } from 'services/config'
import { Portal, Spinner } from 'styleguide/packages/components'
import styles from './index.scss'

const SelectedImage = ({ selectedImage, isLoadingSelected }) => {
  return (
    <div className={`selected-image --${selectedImage.meta.orientation}`}>
      <SensitiveContentBlur isExplicit={Boolean(Number(selectedImage.meta.explicit))}>
        <div className="image-wrapper">
          {isLoadingSelected ? (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          ) : (
            <>
              <Img
                publicId={selectedImage?.before_image?.url}
                alt={getBeforeAfterAltText(selectedImage, 'before')}
              />
              <Img
                publicId={selectedImage?.after_images[0]?.url}
                alt={getBeforeAfterAltText(selectedImage, 'after')}
              />
            </>
          )}
        </div>
      </SensitiveContentBlur>
    </div>
  )
}

const BAProviderModal = ({
  provider,
  selectedProcedure,
  procedureName,
  geoLocationObj,
  procedureTag,
}) => {
  const [photoAlbum, setPhotoAlbum] = useState(null)
  const [providerData, setProviderData] = useState(null)
  const [isFetchingAlbum, setIsFetchingAlbum] = useState(true)
  const [selectedImage, setSelectedImage] = useState(null)
  const [isLoadingSelected, setIsLoadingSelected] = useState(false)

  const windowSize = useWindowSize()

  useEffect(() => {
    if (provider?.id) {
      fetchProviderPhotos()

      const trackData = {
        procedure_id: selectedProcedure.id,
        procedure_name: selectedProcedure.name,
        provider_id: provider.id,
        provider_name: provider.name,
      }

      Track.providerBeforeAfterDetailLoaded(trackData)
    }
  }, [provider])

  const fetchProviderPhotos = async () => {
    setPhotoAlbum(null)
    setIsFetchingAlbum(true)

    const photoAlbum = await fetchPhotoSets(provider, selectedProcedure)
    const providerData = await callApi('GET', `/provider/${provider.id}`)

    setPhotoAlbum(photoAlbum)
    setProviderData(providerData)
    setSelectedImage({ index: 0, ...photoAlbum[0] })
    setIsFetchingAlbum(false)
  }

  const swapImage = (photo, i) => {
    setIsLoadingSelected(true)

    const newSelectedImage = { index: i, ...photo }
    setSelectedImage(newSelectedImage)
    setTimeout(() => setIsLoadingSelected(false), 200)
  }

  const portalContent = photo => {
    return (
      <>
        <SelectedImage selectedImage={photo} isLoadingSelected={isLoadingSelected} />

        <SelectedImageDescription
          provider={providerData}
          procedure={selectedProcedure}
          procedureName={procedureName}
          procedureTag={procedureTag}
          windowSize={windowSize}
          photoAlbum={photoAlbum}
          selectedImage={photo}
          setSelectedImage={setSelectedImage}
          swapImage={swapImage}
          geoLocationObj={geoLocationObj}
        />
      </>
    )
  }

  return (
    <>
      <Portal.Full id="beuro-ba-modal--provider">
        {isFetchingAlbum ? (
          <div>
            <Spinner />
          </div>
        ) : windowSize.width > 768 ? (
          portalContent(selectedImage)
        ) : (
          photoAlbum?.map((photo, i) => {
            const photoObject = { index: i, ...photo }

            return portalContent(photoObject)
          })
        )}
      </Portal.Full>

      <style jsx>{styles}</style>
    </>
  )
}

export default BAProviderModal
