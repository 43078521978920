import React, { useEffect, useContext } from 'react'
import { IntercomSuppressionContext } from './context'

// each component using this is registered to a global intercom suppression list
// intercom chat bubble is only visible when the list is empty
const useHideIntercom = (hide = true) => {
  const { addSuppressor, removeSuppressor } = useContext(IntercomSuppressionContext)

  useEffect(() => {
    if (!hide) return

    let id

    const supress = async () => {
      const { v4: uuidv4 } = await import('uuid')
      id = uuidv4()

      addSuppressor(id)
    }

    supress()

    return () => removeSuppressor(id)
  }, [hide])
}

export default useHideIntercom
