import cn from 'classnames'

const overlayClasses = [
  'fixed',
  '-top-full',
  'right-0',
  'bottom-0',
  'left-0',
  'bg-black',
  'bg-opacity-40',
  'overscroll-none',
  'touch-none',
  'z-[1050]',
  'transition',
  'duration-100',
]

const wrapperStyles = {
  base: [
    'fixed',
    'top-0',
    'right-0',
    'left-0',
    'bottom-0',
    'overscroll-none',
    'overflow-x-hidden',
    'overflow-y-auto',
    'outline-none',
    'z-[1050]',
  ],
  dialogue: ['flex', 'flex-col', 'items-center', 'justify-end', 'md:justify-start', 'md:py-4'],
  mobileFull: ['scrollbar-none', 'md:py-4'],
  userAction: ['flex', 'flex-col', 'items-center', 'justify-center'],
  full: [],
  sidebarRight: ['flex', 'flex-col', 'items-end', 'justify-start'],
  sidebarLeft: ['flex', 'flex-col', 'items-start', 'justify-start'],
}
const commonSidebarStyles = [
  'bg-ivory',
  'rounded-none',
  'w-[90vw]',
  'max-w-[375px]',
  'overflow-y-scroll',
  'md:w-[50vw]',
  'md:max-w-[425px]',
  'lg:w-[30vw]',
  'lg:max-w-[475px]',
]

const containerStyles = {
  base: ['relative', 'bg-white', 'cursor-default', 'z-[1060]'],
  dialogue: [
    'w-full',
    'p-8',
    'max-h-[60%]',
    'rounded-t-xl',
    'rounded-b-none',
    'overflow-y-scroll',
    'animate-slide-up-from-bottom',
    'md:w-fit',
    'md:max-w-xl',
    'md:mx-auto',
    'md:my-auto',
    'md:max-h-fit',
    'md:h-fit',
    'md:rounded-lg',
    'md:overflow-y-visible',
    'md:animate-fade-in-snappy',
  ],
  mobileFull: [
    'w-full',
    'min-h-screen',
    'rounded-none',
    'animate-slide-down-from-top',
    'md:animate-fade-in-snappy',
    'md:max-w-xl',
    'md:min-h-fit',
    'md:mx-auto',
    'md:my-auto',
    'md:rounded-lg',
    'overflow-y-visible',
  ],
  userAction: [
    'w-4/5',
    'p-0',
    'max-w-md',
    'rounded-none',
    'border',
    'border-black',
    'animate-fade-in-snappy',
  ],
  full: ['w-full', 'min-h-screen', 'animate-fade-in-snappy'],
  sidebarRight: [...commonSidebarStyles, 'animate-right-sidebar-in'],
  sidebarLeft: [...commonSidebarStyles, 'animate-left-sidebar-in'],
}

const kebabToCamelCase = s => s.replace(/-./g, x => x[1].toUpperCase())

export const buildPortalClasses = type => {
  // sidebar-right -> sidebarRight
  const camelizedType = kebabToCamelCase(type)
  return {
    overlayCn: cn('portal-overlay', overlayClasses),
    wrapperCn: cn(wrapperStyles.base, wrapperStyles[camelizedType]),
    containerCn: cn(containerStyles.base, containerStyles[camelizedType]),
  }
}
