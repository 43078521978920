import area from './area/index.js'
import career from './career/index.js'
import contact from './contact/index.js'
import systems from './systems/index.js'
import large from './large/index.js'

export default {
  ...area,
  ...career,
  ...contact,
  ...systems,
  ...large,
}
