import React from 'react'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import Icon from '../icon'
import Spinner from '../spinner'
import { classes } from 'styleguide/packages/util/index.js'
import PropTypes from 'prop-types'

const BaseBtn = props => {
  const {
    name = '',
    type = 'submit',
    className = '',
    onClick,
    content = '',
    icon = null,
    iconSize = 16,
    isFixed = false,
    isSmall = false,
    isFormik = false,
    isPending = false,
    disabled = false,
    isDirty = true,
    ...rest
  } = props
  const form = isFormik && useFormikContext()

  const isSubmitButton = type === 'submit'
  const isSubmitting = isPending || (form && (form.isValidating || form.isSubmitting))

  const isForm = form && (isSubmitButton || isFormik)
  const isInvalidForm = isForm && (!isEmpty(form.errors) || (isDirty && !form.dirty))
  const disableForm = disabled || isInvalidForm || (!isSubmitButton && isSubmitting)
  const showSpinner = isSubmitButton && isSubmitting

  const classNames = classes([
    className,
    {
      '--disabled': disableForm,
      '--fixed': isFixed,
      '--small': isSmall,
      '--submitting': isSubmitting,
    },
  ])

  return (
    <button
      name={name}
      type={type}
      className={classNames}
      disabled={disableForm}
      onClick={onClick}
      aria-label={name.replace(/-/g, ' ')}
      {...rest}>
      <div className={`${icon || showSpinner ? 'content-wrapper' : ''} ${!content && '--no-text'}`}>
        {content && <p className={showSpinner ? '--hide' : ''}>{content}</p>}
        {icon && (
          <Icon
            className={`icon-container ${showSpinner ? '--hide' : ''}`}
            fontSize={iconSize}
            name={icon}
          />
        )}
        {showSpinner && <Spinner />}
      </div>
    </button>
  )
}

BaseBtn.propTypes = {
  name: PropTypes.string,
  isFixed: PropTypes.bool,
  isSmall: PropTypes.bool,
  isFormik: PropTypes.bool,
  isPending: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default BaseBtn
