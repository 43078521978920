import { useEffect, useState } from 'react'

const useDocumentFocus = () => {
  const [hasFocus, setFocusState] = useState(false)

  useEffect(() => {
    const setFocused = () => setFocusState(true)
    const setUnfocused = () => setFocusState(false)
    const visibilityHandler = () => {
      if (document.visibilityState === 'visible') {
        setFocused()
        return
      }
      if (document.visibilityState === 'hidden') {
        setUnfocused()
        return
      }
    }
    const associations = [
      ['focus', setFocused],
      ['blur', setUnfocused],
      ['visibilitychange', visibilityHandler],
      ['pagehide', visibilityHandler],
    ]
    associations.forEach(([eventName, handler]) => {
      document.addEventListener(eventName, handler)
    })

    return () => {
      associations.forEach(([eventName, handler]) => {
        document.removeEventListener(eventName, handler)
      })
    }
  }, [])

  return hasFocus
}

export default useDocumentFocus
