import React from 'react'
import { getInitials } from 'services/util/format'
import { getProfilePictureAltText } from 'services/util/image'
import styles from './profile_image.scss'
import Img from 'components/img'
import Icon from 'components/icon'

const ProfileImage = props => {
  const { user, hideBadge, type = null, lazy = true, className = '' } = props

  const profile = {
    ...user,
    _type: type ?? user._type,
  }

  const alt = getProfilePictureAltText(profile)

  return (
    <>
      <span className={`profile-pic ${className}`}>
        {user.profile_picture_relative_url ? (
          <Img
            publicId={user.profile_picture_relative_url}
            alt={alt}
            width="120"
            height="120"
            gravity="face"
            crop="thumb"
            zoom="0.7"
            lazy={lazy}
          />
        ) : (
          <span className="initials">{getInitials(user.first_name + ' ' + user.last_name)}</span>
        )}

        {Boolean(user.is_beauty_board_member) && !hideBadge && (
          <span className="bbb overline-sm">board</span>
        )}

        {Boolean(user.accept_video_consultations) && !hideBadge && (
          <span className="video-icon-wrapper mr-2">
            <Icon name="v2-video" className="video-icon" fontSize="11px" />
          </span>
        )}
      </span>

      <style jsx global>
        {styles}
      </style>
    </>
  )
}

ProfileImage.defaultProps = {
  hideBadge: false,
}

export default ProfileImage
