import { useEffect, useState } from 'react'

const useMessageEvent = () => {
  const [handler, _setHandler] = useState(null)

  useEffect(() => {
    return () => window.removeEventListener('message', handler)
  }, [handler])

  const setHandler = newHandler => {
    _setHandler(oldHandler => {
      if (Object.is(oldHandler, newHandler)) {
        return oldHandler
      }

      window.removeEventListener('message', oldHandler)
      window.addEventListener('message', newHandler)

      return newHandler
    })
  }

  /**
   * Sends new message through the MessageEvent API
   *
   * @param {string} sender Message sender
   * @param {*} payload Message content
   * @param {*} target Target window where to post the message. Defaults to window.
   */
  const sendMessage = ({ sender, payload, target = null }) => {
    if (!sender) {
      throw new Error('Missing required parameter sender')
    }

    const message = { sender, payload }

    target = target ?? window
    const origin = target.origin // no cross-domain messaging

    target.postMessage(message, origin)
  }

  /**
   * Receives exactly one message with specified parameters through the MessageEvent API
   *
   * This function does not resolve immediately, but instead it waits for a new message.
   *
   * @param {?string=} sender Message sender. If not provided then receive message from any sender.
   */
  const receiveMessage = async sender => {
    const origin = window.location.origin // no cross-domain messaging

    return await new Promise((resolve, reject) => {
      setHandler(envelope => {
        try {
          envelope = envelope ?? {}
          const message = envelope.data ?? {}

          if (envelope.origin !== origin) {
            return false
          }

          if (sender && message.sender !== sender) {
            return false
          }

          setHandler(null)
          return resolve(message.payload)
        } catch (err) {
          return reject(err)
        }
      })
    })
  }

  return { sendMessage, receiveMessage }
}

export default useMessageEvent
