import React from 'react'
import svgs from './svgs/index.js'

const BEURO__Icon = props => {
  const {
    name = '',
    className = '',
    size = '1em',
    width = '32',
    height = '32',
    viewBox = '0 0 32 32',
    onClick,
    color,
    ...rest
  } = props

  return (
    <svg
      className={`icon icon-${name} ${className}`}
      onClick={onClick}
      style={{
        fontSize: size,
      }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      {...rest}>
      <title>{name}</title>
      {svgs[name] ? svgs[name](color) : <></>}
    </svg>
  )
}

export default BEURO__Icon
