import { useCallback, useContext, useMemo } from 'react'
import NotificationContext from './context'

const useNotifications = requestDetailString => {
  const { permission, triggerPermissionFlow } = useContext(NotificationContext)

  const requestFromLocalContext = useCallback(
    () => triggerPermissionFlow(requestDetailString),
    [requestDetailString, triggerPermissionFlow]
  )

  const stateAndRequestFunction = useMemo(
    () => [permission === 'granted', requestFromLocalContext],
    [requestDetailString, requestFromLocalContext]
  )

  return stateAndRequestFunction
}

export default useNotifications
