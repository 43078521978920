import PropTypes from 'prop-types'
import Img from 'components/img/cloudinary-img'

const getPublicId = name => `/website/iconography/popup-icons/${name.replace(' ', '+')}.png`
export default function PopupIcon({ name }) {
  return <Img publicId={getPublicId(name)} />
}

PopupIcon.propTypes = {
  name: PropTypes.string.isRequired,
}
