import React from 'react'
import { Track } from 'services/analytics'
import Img from 'components/img/cloudinary-img'
import ProfileImage from 'components/profile_image'
import SensitiveContentBlur from 'components/sensitive_content_blur'
import { getBeforeAfterAltText } from 'services/util/image'
import { fullTitle } from 'services/util/provider'
import { BEURO__Icon, Chiclet } from 'styleguide/packages/components'
import styles from './index.scss'

const BAPhotoAlbum = ({
  className,
  procedureName,
  totalAlbums,
  albumCover,
  albumLength = null,
  renderChiclet = false,
  baModalOpen = false,
  onClick: _onClick,
}) => {
  const { meta, provider, procedure, before_image, after_images } = albumCover
  const { city: providerCity, state_abbreviation: providerState } =
    provider.primary_practice?.location || {}

  const trackData = {
    procedure_id: procedure.id,
    procedure_name: procedure.name,
    provider_id: provider.id,
    provider_name: provider.first_name + ' ' + provider.last_name,
  }

  const albumClicked = () => {
    Track.providerAlbumsClicked(trackData)
    _onClick?.()
  }

  return (
    <>
      <SensitiveContentBlur
        className={`${className ? className : ''}`}
        isExplicit={Boolean(Number(meta.explicit))}
        toggleCondition={baModalOpen}
        onClick={albumClicked}>
        <div className={`overlay ${renderChiclet ? '--render-chiclet' : ''}`} />

        {albumLength && (
          <p className="BEURO__body procedure-count">
            <span>
              {procedureName} ({albumLength} {`${albumLength === 1 ? 'Image' : 'Images'}`})
            </span>
          </p>
        )}

        <div className="image-wrapper">
          <div className={`images --${meta.orientation}`}>
            <Img publicId={before_image.url} alt={getBeforeAfterAltText(albumCover, 'before')} />
            <Img publicId={after_images[0].url} alt={getBeforeAfterAltText(albumCover, 'after')} />
          </div>

          <div className="provider-info-wrapper">
            <div className="profile-image-wrapper">
              <ProfileImage user={provider} className="--beuro" />
            </div>

            <div className="provider-info hidden-xs">
              <p className="BEURO__overline provider-name leading-3">{fullTitle(provider)}</p>
              <p className="BEURO__overline BEURO__overline--small pt-1">{procedure.name}</p>
              <span className="flex gap-1">
                <BEURO__Icon name="pin" width="16" />
                <p className="BEURO__overline BEURO__overline--small provider-location">
                  {providerCity}, {providerState}
                </p>
              </span>
            </div>
          </div>

          <div className="chiclet-wrapper">
            {renderChiclet && <Chiclet.Gold>+{totalAlbums} More</Chiclet.Gold>}
          </div>
        </div>

        <style jsx>{styles}</style>
      </SensitiveContentBlur>
    </>
  )
}

export default BAPhotoAlbum
