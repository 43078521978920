import React from 'react'

// color variables
const $BEURO__link = '#20685d'

const icons = {
  commute: () => (
    <path d="M7.289 9.867h17.422v6.378l-17.422-0.010v-6.367zM24.701 9.244h-17.412v-0.355c0-1.62 1.313-2.934 2.933-2.934h11.556c1.62 0 2.933 1.313 2.933 2.933v0.356h-0.011zM7.289 16.867h17.422v3.578c0 1.62-1.313 2.933-2.933 2.933h-11.556c-1.62 0-2.933-1.313-2.933-2.933v-3.578zM6.667 16.244v4.2c0 1.953 1.575 3.539 3.525 3.555l-1.131 2.073c-0.082 0.151-0.027 0.34 0.124 0.422s0.34 0.027 0.422-0.124l1.293-2.371h10.2l1.293 2.371c0.082 0.151 0.271 0.206 0.422 0.124s0.206-0.271 0.124-0.422l-1.131-2.073c1.949-0.017 3.524-1.602 3.524-3.555v-11.556c0-1.964-1.592-3.556-3.556-3.556h-11.556c-1.964 0-3.556 1.592-3.556 3.556v7.355zM10.667 20.444c-0.245 0-0.444-0.199-0.444-0.444s0.199-0.444 0.444-0.444c0.245 0 0.444 0.199 0.444 0.444s-0.199 0.444-0.444 0.444zM21.778 20c0-0.245-0.199-0.444-0.444-0.444s-0.444 0.199-0.444 0.444c0 0.245 0.199 0.444 0.444 0.444s0.444-0.199 0.444-0.444zM12.756 25.6h6.489c0.172 0 0.311 0.139 0.311 0.311s-0.139 0.311-0.311 0.311h-6.489c-0.172 0-0.311-0.139-0.311-0.311s0.139-0.311 0.311-0.311z"></path>
  ),
  composting: () => (
    <>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M10.055 8.889c-1.398 0.577-3.312 1.58-5.257 3.221-1.55 1.337-2.674 2.705-3.464 3.799 0.942 1.124 2.158 2.431 3.708 3.738 1.854 1.519 3.647 2.613 5.105 3.373-0.547-1.55-1.216-3.981-1.246-7.020 0-3.069 0.638-5.531 1.155-7.111z"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M9.022 15.97h17.535"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M30.446 12.748c-0.669 0.274-1.55 0.729-2.462 1.489-0.729 0.608-1.276 1.246-1.641 1.763 0.425 0.547 1.003 1.124 1.732 1.732 0.851 0.729 1.702 1.216 2.401 1.58-0.273-0.729-0.577-1.884-0.577-3.282s0.304-2.553 0.547-3.282z"></path>
      <path d="M6.222 14.667c0.245 0 0.444-0.199 0.444-0.444s-0.199-0.444-0.444-0.444c-0.245 0-0.444 0.199-0.444 0.444s0.199 0.444 0.444 0.444z"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M13.884 8.889c-0.547 1.428-1.185 3.738-1.155 6.595 0.030 3.404 0.972 6.017 1.641 7.506"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M17.318 9.679c-0.486 1.276-1.033 3.312-1.033 5.865 0.030 3.009 0.851 5.349 1.459 6.686"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M20.691 11.077c-0.365 1.003-0.82 2.583-0.82 4.558 0 2.34 0.669 4.194 1.124 5.227"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M24.095 12.688c-0.243 0.669-0.547 1.732-0.547 3.039 0 1.58 0.456 2.796 0.76 3.464"></path>
    </>
  ),
  development: () => (
    <path d="M13.689 6c0-0.172 0.139-0.311 0.311-0.311h4.311v4.311c0 0.172-0.139 0.311-0.311 0.311s-0.311-0.139-0.311-0.311v-3.249l-8.802 8.802c-0.121 0.121-0.319 0.121-0.44 0s-0.121-0.319 0-0.44l8.802-8.802h-3.249c-0.172 0-0.311-0.139-0.311-0.311zM26.933 6.4v19.2h-4.089v-19.2h4.089zM22.844 5.778h-0.622v20.444h5.333v-20.444h-4.711zM13.956 17.067v8.533h4.089v-8.533h-4.089zM13.333 16.444h5.333v9.778h-5.333v-9.778zM5.067 22.4v3.2h4.089v-3.2h-4.089zM4.444 21.778h5.333v4.444h-5.333v-4.444z"></path>
  ),
  food: () => (
    <path d="M1.867 5.555c0-0.172-0.139-0.311-0.311-0.311s-0.311 0.139-0.311 0.311v5.591c0 1.67 0.982 3.171 2.372 3.368v11.93c0 0.172 0.139 0.311 0.311 0.311s0.311-0.139 0.311-0.311v-11.93c1.39-0.197 2.372-1.697 2.372-3.367v-5.592c0-0.172-0.139-0.311-0.311-0.311s-0.311 0.139-0.311 0.311v5.592c0 1.451-0.808 2.535-1.75 2.734v-8.326c0-0.172-0.139-0.311-0.311-0.311s-0.311 0.139-0.311 0.311v8.326c-0.942-0.199-1.75-1.284-1.75-2.734v-5.591zM30.347 5.888c-1.1 0.152-1.947 1.096-1.947 2.237v9.629h1.947v-11.866zM30.969 5.556v12.821h-2.569v8.068c0 0.172-0.139 0.311-0.311 0.311s-0.311-0.139-0.311-0.311v-18.319c0-1.591 1.29-2.881 2.881-2.881 0.172 0 0.311 0.139 0.311 0.311zM16.518 7.793c-4.533 0-8.207 3.674-8.207 8.207s3.674 8.207 8.207 8.207 8.207-3.674 8.207-8.207c0-4.533-3.674-8.207-8.207-8.207zM7.689 16c0-4.876 3.953-8.829 8.829-8.829s8.829 3.953 8.829 8.829c0 4.876-3.953 8.829-8.829 8.829s-8.829-3.953-8.829-8.829zM11.942 16c0-2.528 2.050-4.578 4.578-4.578s4.578 2.050 4.578 4.578c0 2.528-2.050 4.578-4.578 4.578s-4.578-2.050-4.578-4.578zM16.52 10.8c-2.872 0-5.2 2.328-5.2 5.2s2.328 5.2 5.2 5.2c2.872 0 5.2-2.328 5.2-5.2s-2.328-5.2-5.2-5.2z"></path>
  ),
  handwash: () => (
    <>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M10.667 26.67h11.121v-12.312c0-1.381-0.611-2.536-1.65-3.238-0.95-0.642-1.527-1.215-1.527-2.36v-0.456c0-0.478-0.389-0.867-0.867-0.867h-3.031c-0.478 0-0.868 0.389-0.868 0.867v0.456c0 0.782-0.525 1.576-1.561 2.36-1.012 0.765-1.616 1.976-1.616 3.238v12.312z"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M14.361 22.037l-0-0c-0.364-0.381-0.523-0.913-0.418-1.424v-0c0.303-1.469 1.712-2.97 4.577-3.049 0 0 0 0 0 0l0.633-0.017-0.046 0.631c-0.17 2.322-1.289 4.032-3.103 4.407-0.575 0.119-1.162-0.046-1.559-0.46l-0.083-0.087zM14.361 22.037v0z"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M16.639 19.93c-1.354 0.467-2.121 1.93-2.121 1.93l-1.185 1.785"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M10.667 14.444h11.111"></path>
      <path d="M10.889 3.111h-0.311v2.533c0 0.172 0.139 0.311 0.311 0.311s0.311-0.139 0.311-0.311v-1.911h4.8v3.467h0.622v-3.467h3.378c0.172 0 0.311-0.139 0.311-0.311s-0.139-0.311-0.311-0.311h-9.111z"></path>
    </>
  ),
  healthcare: () => (
    <>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M17.424 14.037l0.503 1.066c0.317 0.574 0.684 1.118 1.097 1.626 0.731 0.901 1.943 2.097 3.642 2.826l1.117 0.444M28.595 11.425c-0.27-0.667-0.701-1.256-1.255-1.715s-1.212-0.774-1.917-0.917c-0.705-0.143-1.434-0.109-2.123 0.099s-1.315 0.583-1.823 1.092l-8.8 8.8c-0.817 0.817-1.276 1.925-1.276 3.081s0.459 2.264 1.276 3.081c0.817 0.817 1.925 1.276 3.081 1.276s2.264-0.459 3.081-1.276l8.8-8.8c0.605-0.606 1.019-1.377 1.189-2.216s0.089-1.71-0.232-2.504z"></path>
      <path
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M13.76 6.658c1.961 1.961 1.961 5.141 0 7.102s-5.141 1.961-7.103 0c-1.961-1.961-1.961-5.141 0-7.103s5.141-1.961 7.103 0z"></path>
    </>
  ),
  'led-lights': () => (
    <>
      <path
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M16 10.667c-4.909 0-8.889 3.98-8.889 8.889h17.778c0-4.909-3.98-8.889-8.889-8.889z"></path>
      <path
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M16 23.111c1.964 0 3.556-1.592 3.556-3.556h-7.111c0 1.964 1.592 3.556 3.556 3.556z"></path>
      <path
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M16 10.444v-8"></path>
      <path
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M16.311 26.089v2.933"></path>
      <path
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M9.040 26.074l2.074-2.074"></path>
      <path
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M20.884 24l2.074 2.074"></path>
    </>
  ),
  mugs: () => (
    <>
      <path
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M8.889 26.339h10.667"></path>
      <path
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M14.222 23.689c3.682 0 6.667-2.985 6.667-6.667v-4.889h-13.334v4.889c0 3.682 2.985 6.667 6.667 6.667z"></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M14.215 5.778c0 0-0.68 0.981-0.59 1.665 0.077 0.582 0.699 0.715 0.758 1.299 0.060 0.601-0.516 1.457-0.516 1.457"></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M10.66 5.778c0 0-0.68 0.981-0.59 1.665 0.077 0.582 0.699 0.715 0.758 1.299 0.060 0.601-0.516 1.457-0.516 1.457"></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M17.771 5.778c0 0-0.68 0.981-0.59 1.665 0.077 0.582 0.699 0.715 0.758 1.299 0.060 0.601-0.516 1.457-0.516 1.457"></path>
      <path
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M20.796 14.355c1.964 0 3.556 1.592 3.556 3.556s-1.592 3.556-3.556 3.556c-0.436 0-0.854-0.079-1.24-0.222"></path>
    </>
  ),
  'office-plant': () => (
    <>
      <path d="M16.406 10.379c-0.079 0.153-0.019 0.34 0.134 0.419s0.34 0.019 0.419-0.134l-0.553-0.285zM19.736 8.129c0.162-0.056 0.249-0.233 0.193-0.396s-0.233-0.249-0.396-0.193l0.203 0.588zM16.959 10.663c0.015-0.029 0.269-0.515 0.745-1.070 0.478-0.557 1.162-1.164 2.032-1.464l-0.203-0.588c-1.014 0.35-1.785 1.045-2.301 1.647-0.518 0.604-0.797 1.136-0.825 1.19l0.553 0.285z"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M9.321 4.357l0.015-0.311-0.328-0.016 0.002 0.328 0.311-0.002zM9.321 4.357c0.015-0.311 0.015-0.311 0.015-0.311h0l0.001 0 0.002 0 0.009 0 0.032 0.002c0.028 0.002 0.068 0.004 0.12 0.008 0.103 0.008 0.251 0.021 0.433 0.041 0.363 0.041 0.863 0.111 1.409 0.23 1.075 0.233 2.405 0.668 3.196 1.494 0.757 0.79 1.245 1.715 1.411 2.581 0.165 0.863 0.015 1.714-0.574 2.278s-1.445 0.677-2.301 0.474c-0.859-0.204-1.761-0.732-2.517-1.522-0.791-0.826-1.167-2.174-1.354-3.258-0.095-0.552-0.143-1.054-0.168-1.418-0.012-0.182-0.019-0.331-0.022-0.434-0.002-0.052-0.003-0.092-0.003-0.12l-0.001-0.032-0-0.009-0-0.002v-0.001c0-0 0-0 0.311-0.002z"></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6222"
        d="M22.716 4.357l-0.015-0.311 0.328-0.016-0.002 0.328-0.311-0.002zM22.716 4.357c-0.015-0.311-0.015-0.311-0.015-0.311h-0l-0.012 0.001-0.032 0.002c-0.028 0.002-0.068 0.004-0.12 0.008-0.103 0.008-0.251 0.021-0.433 0.041-0.363 0.041-0.863 0.111-1.409 0.23-1.075 0.233-2.405 0.668-3.196 1.494-0.757 0.79-1.245 1.715-1.411 2.581-0.165 0.863-0.015 1.714 0.574 2.278s1.445 0.677 2.301 0.474c0.859-0.204 1.761-0.732 2.517-1.522 0.791-0.826 1.167-2.174 1.354-3.258 0.095-0.552 0.143-1.054 0.168-1.418 0.012-0.182 0.019-0.331 0.022-0.434 0.002-0.052 0.003-0.092 0.003-0.12l0-0.032 0-0.009v-0.003c0-0 0-0-0.311-0.002z"></path>
      <path d="M15.594 10.304c0.079 0.153 0.019 0.34-0.134 0.419s-0.34 0.019-0.419-0.134l0.553-0.285zM12.264 8.054c-0.162-0.056-0.249-0.233-0.193-0.396s0.233-0.249 0.396-0.193l-0.203 0.588zM15.041 10.588c-0.015-0.029-0.269-0.515-0.745-1.070-0.478-0.557-1.162-1.164-2.032-1.464l0.203-0.588c1.014 0.35 1.785 1.045 2.301 1.647 0.518 0.604 0.797 1.136 0.825 1.19l-0.553 0.285z"></path>
      <path d="M16.4 15.244h4.711c0.663 0 1.2 0.537 1.2 1.2v2.222c0 0.112-0.059 0.21-0.148 0.265l-1.41 6.343c-0.181 0.813-0.902 1.392-1.735 1.392h-6.037c-0.833 0-1.555-0.579-1.735-1.392l-1.41-6.343c-0.089-0.055-0.148-0.153-0.148-0.265v-2.222c0-0.663 0.537-1.2 1.2-1.2h4.889v-5.467h0.622v5.467zM21.689 16.444c0-0.319-0.259-0.578-0.578-0.578h-10.222c-0.319 0-0.578 0.259-0.578 0.578v2.222h11.378v-2.222zM10.553 19.289l1.3 5.851c0.117 0.529 0.586 0.905 1.128 0.905h6.037c0.542 0 1.011-0.376 1.128-0.905l1.3-5.851h-10.893z"></path>
    </>
  ),
  recycle: () => (
    <>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M21.651 14.984l5.587 9.651h-8.127"></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M10.476 14.984l5.587-9.651 4.571 8.127"></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M16.347 24.635h-11.458l4.571-8.127"></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M15.048 26.667l2.032-2.032-2.032-2.032"></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M9.968 12.444l0.508 2.54 2.54-0.508"></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.6222"
        d="M24.191 15.492l-2.54-0.508-0.508 2.54"></path>
    </>
  ),
  team: () => (
    <path d="M23.519 9.737c0-1.278 1.036-2.315 2.315-2.315s2.315 1.036 2.315 2.315c0 1.278-1.036 2.315-2.315 2.315s-2.315-1.036-2.315-2.315zM25.834 6.8c-1.622 0-2.937 1.315-2.937 2.937s1.315 2.937 2.937 2.937 2.937-1.315 2.937-2.937c0-1.622-1.315-2.937-2.937-2.937zM12.989 13.401c0-1.641 1.33-2.971 2.971-2.971s2.971 1.33 2.971 2.971c0 1.641-1.33 2.971-2.971 2.971s-2.971-1.33-2.971-2.971zM15.96 9.808c-1.985 0-3.593 1.609-3.593 3.593s1.609 3.593 3.593 3.593c1.985 0 3.593-1.609 3.593-3.593s-1.609-3.593-3.593-3.593zM12.678 19.654c-0.953 0-1.867 0.379-2.541 1.052s-1.053 1.588-1.053 2.541v1.641c0 0.172 0.139 0.311 0.311 0.311s0.311-0.139 0.311-0.311v-1.641c0-0.788 0.313-1.544 0.87-2.101s1.313-0.87 2.101-0.87h6.565c0.788 0 1.544 0.313 2.101 0.87s0.87 1.313 0.87 2.101v1.641c0 0.172 0.139 0.311 0.311 0.311s0.311-0.139 0.311-0.311v-1.641c0-0.953-0.379-1.867-1.052-2.541s-1.588-1.052-2.541-1.052h-6.564zM21.131 15.537c0.551-0.551 1.298-0.86 2.077-0.86h5.252c0.779 0 1.526 0.309 2.077 0.86s0.86 1.298 0.86 2.077v3.98c0 0.172-0.139 0.311-0.311 0.311s-0.311-0.139-0.311-0.311v-3.98c0-0.614-0.244-1.203-0.678-1.637s-1.023-0.678-1.637-0.678h-5.252c-0.614 0-1.203 0.244-1.637 0.678s-0.678 1.023-0.678 1.637c0 0.172-0.139 0.311-0.311 0.311s-0.311-0.139-0.311-0.311c0-0.779 0.309-1.526 0.86-2.077zM1.438 15.537c0.551-0.551 1.298-0.86 2.077-0.86h5.252c0.779 0 1.526 0.309 2.077 0.86s0.86 1.298 0.86 2.077c0 0.172-0.139 0.311-0.311 0.311s-0.311-0.139-0.311-0.311c0-0.614-0.244-1.203-0.678-1.637s-1.023-0.678-1.637-0.678h-5.252c-0.614 0-1.203 0.244-1.637 0.678s-0.678 1.023-0.678 1.637v3.98c0 0.172-0.139 0.311-0.311 0.311s-0.311-0.139-0.311-0.311v-3.98c0-0.779 0.309-1.526 0.86-2.077zM3.826 9.737c0-1.278 1.036-2.315 2.315-2.315s2.315 1.036 2.315 2.315c0 1.278-1.036 2.315-2.315 2.315s-2.315-1.036-2.315-2.315zM6.141 6.8c-1.622 0-2.937 1.315-2.937 2.937s1.315 2.937 2.937 2.937c1.622 0 2.937-1.315 2.937-2.937s-1.315-2.937-2.937-2.937z"></path>
  ),
  vacation: () => (
    <path d="M6.089 5.333c-0.172 0-0.311 0.139-0.311 0.311s0.139 0.311 0.311 0.311h3.796l1.504 3.467h-2.411v5.111c0 3.823 3.017 6.942 6.8 7.104l-0 0.007v4.356h-2.8c-0.172 0-0.311 0.139-0.311 0.311s0.139 0.311 0.311 0.311h6.222c0.172 0 0.311-0.139 0.311-0.311s-0.139-0.311-0.311-0.311h-2.8v-4.356l-0-0.007c3.783-0.163 6.8-3.281 6.8-7.104v-0.825c1.926-0.22 3.422-1.856 3.422-3.841 0-2.136-1.731-3.867-3.867-3.867-1.985 0-3.621 1.496-3.841 3.422h-6.847l-1.774-4.089h-4.204zM11.659 10.044l3.255 7.502c0.068 0.158 0.252 0.23 0.409 0.162s0.23-0.252 0.162-0.409l-3.148-7.254h10.24v4.489c0 3.584-2.905 6.489-6.489 6.489s-6.489-2.905-6.489-6.489v-4.489h2.059zM19.541 9.422c0.217-1.582 1.573-2.8 3.214-2.8 1.792 0 3.244 1.453 3.244 3.244 0 1.641-1.218 2.998-2.8 3.214v-3.659h-3.659z"></path>
  ),
}

export default icons
