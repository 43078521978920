import React, { useState, useContext, useEffect } from 'react'
import { GlobalContext } from 'components/global'
import useAuthRequired from 'hooks/use_auth_required'
import AeditLink from 'services/link'
import BAPhotoAlbum from './ba_photo_album'
import BAGalleryModal from './ba_modal_gallery'
import BAProviderModal from './ba_modal_provider'
import { showBAPrePaywallModal } from './ba_modal_learn_more'
import { BEURO__Btn, Portal } from 'styleguide/packages/components'
import { toggleOpen } from 'styleguide/packages/util/index.js'
import { isValidPhotoAlbum } from './_util'
import { FlagSmithStateContext } from 'services/flagsmith/state'
import { AeditLogo } from 'components/logos'
import { hasProSubscription } from 'services/util/user'
import { SUBSCRIPTION_SOURCES } from 'services/util/payment'
import styles from './index.scss'

const BAGalleryBeuro = props => {
  let { title, algoliaBAData, geoLocationObj, analytics: _analytics, ...procedure } = props

  const source = _analytics?.source ?? SUBSCRIPTION_SOURCES.PROCEDURE_B_A
  const analytics = { ..._analytics, source, procedure_id: procedure.id }

  const [baModalOpen, setBaModalOpen] = useState(false)
  const [provider, setProvider] = useState({})
  const [selectedProcedure, setSelectedProcedure] = useState({})
  const { config, currentUser, setGlobalState } = useContext(GlobalContext)
  const { flags } = useContext(FlagSmithStateContext)
  const proSubscriptionFlag = Boolean(flags.web_pro_paywall.enabled)
  const isProUser = hasProSubscription(currentUser, config)

  const photoAlbums = (algoliaBAData.photoAlbums ?? []).filter(isValidPhotoAlbum)
  algoliaBAData = { ...algoliaBAData, photoAlbums }

  const procedureName =
    !procedure.is_parent || procedure.name.toLowerCase().includes('solutions')
      ? procedure.name
      : (procedure.name += ' Solutions')
  const procedureTag =
    selectedProcedure.id === procedure.id && !procedure.parent_procedure
      ? null
      : procedure.parent_procedure
      ? procedure.parent_procedure.name
      : selectedProcedure.name

  const openBaModal = useAuthRequired({
    postAuth: () => {
      setBaModalOpen(true)
      toggleOpen('beuro-ba-modal--gallery')
    },
    label: 'loginCopy.baModal',
    analytics,
    source,
  })

  const showPrePaywallModal = params => {
    toggleOpen('beuro-ba-modal--gallery')
    showBAPrePaywallModal({ ...analytics, ...params })
  }

  useEffect(() => {
    if (
      (proSubscriptionFlag && isProUser && currentUser?.completed_signup) ||
      (!proSubscriptionFlag && currentUser?.completed_signup)
    ) {
      setBaModalOpen(true)
    } else {
      setBaModalOpen(false)
      setGlobalState({ shouldBlurExplicitContent: true })
    }

    return () => {}
  }, [currentUser])

  return (
    <>
      <section id="ba-module" className="ba-module-beuro m-full-screen">
        {proSubscriptionFlag && (
          <div className="flex justify-start mb-2">
            <AeditLogo className="justify-start" size="medium" pro />
          </div>
        )}

        <h3 className="BEURO__subsection-header mb-4">
          {title || 'Before & After Images by Provider'}
        </h3>

        <div className="module-content">
          {!proSubscriptionFlag && !baModalOpen && !Boolean(procedure.explicit) && (
            <div className="sign-up-wrapper">
              <BEURO__Btn.Gold
                className="sign-up-btn"
                content="Sign up or Login to Browse Real Before & Afters"
                onClick={() => openBaModal()}
              />
            </div>
          )}

          <div
            className={`ba-album-wrapper --length-${photoAlbums.length} ${
              !baModalOpen && !Boolean(procedure.explicit) ? '--blurred' : ''
            }
          `}>
            {photoAlbums.slice(0, 4).map((album, i) => (
              <BAPhotoAlbum
                procedureName={procedureName}
                albumCover={album}
                albumLength={album.length}
                totalAlbums={photoAlbums.length}
                renderChiclet={Boolean(i === 3)}
                baModalOpen={baModalOpen}
                onClick={() => {
                  const data = { provider_id: album.provider.id }

                  if (proSubscriptionFlag && !isProUser) {
                    showPrePaywallModal(data)
                  } else {
                    openBaModal(data)
                  }
                }}
              />
            ))}
          </div>
        </div>

        <div className="button-wrapper">
          {proSubscriptionFlag && !baModalOpen && !Boolean(procedure.explicit) ? (
            <>
              <BEURO__Btn.Gold
                className="sign-up-btn"
                content="Join Pro to browse all images"
                onClick={showPrePaywallModal}
              />
            </>
          ) : proSubscriptionFlag && baModalOpen ? (
            <Portal.ToggleOpen
              id="beuro-ba-modal--gallery"
              toggleCondition={baModalOpen}
              onClick={() => openBaModal()}>
              <BEURO__Btn.Gold content="Browse all images" />
            </Portal.ToggleOpen>
          ) : (
            <>
              <Portal.ToggleOpen
                id="beuro-ba-modal--gallery"
                toggleCondition={baModalOpen}
                onClick={() => openBaModal()}>
                <BEURO__Btn.Transparent content="Browse all images" />
              </Portal.ToggleOpen>

              <AeditLink
                className="find-provider-btn"
                pathname="/p-[slug]-providers"
                as={`/p-${procedure.slug}-providers`}
                params={{ procedure: procedure.name }}>
                <BEURO__Btn.Gold content="Find a provider" />
              </AeditLink>
            </>
          )}
        </div>

        <BAGalleryModal
          title={title}
          baModalOpen={baModalOpen}
          geoLocationObj={geoLocationObj}
          algoliaBAData={algoliaBAData}
          setProvider={setProvider}
          procedureName={procedureName}
          setSelectedProcedure={setSelectedProcedure}
          procedureTag={procedureTag}
          analytics={analytics}
          {...procedure}
        />

        <BAProviderModal
          provider={provider}
          selectedProcedure={selectedProcedure}
          procedureName={procedureName}
          procedureTag={procedureTag}
          geoLocationObj={geoLocationObj}
        />

        <style jsx>{styles}</style>
      </section>
    </>
  )
}

export default BAGalleryBeuro
