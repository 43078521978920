import { Portal, createPortal, usePortal, showPortal } from 'components/portal'
import Btn from 'components/buttons/base.js'
import { AeditLogo } from 'components/logos'
import { ProPaywallModal } from 'components/modals'
import { Track } from 'services/analytics'

const BAPrePaywallModal = createPortal(props => {
  const modalControls = usePortal()

  const handleClose = () => {
    modalControls.remove()
  }

  const openProPaywall = () => {
    handleClose()

    showPortal(ProPaywallModal, props)
  }

  return (
    <Portal.Dialogue className="text-center" onClose={handleClose}>
      <AeditLogo pro size="medium" />
      <h2 className="t-subsection-header my-4">Join PRO to browse Before & After Photos</h2>
      <p className="t-body-sm">
        Members get access to thousands of before & afters from top doctors. With dozens of photos
        uploaded each week, PRO member get access to the newest before and afters.
      </p>

      <div className="flex justify-center mt-11 w-full">
        <Btn.Gold
          content="Learn More"
          onClick={openProPaywall}
          classNames={{ container: 'w-full md:w-32' }}
        />
      </div>
    </Portal.Dialogue>
  )
})

export const showBAPrePaywallModal = analytics => {
  analytics = analytics ?? {}

  Track.gatedContentClicked(analytics)

  const paywallProps = { analytics }
  showPortal(BAPrePaywallModal, paywallProps)
}

export default BAPrePaywallModal
