import flagsmith from 'flagsmith/isomorphic'
import { FLAGSMITH_TOKEN } from 'services/constants'
import { getCookie, removeCookie, setCookie } from 'services/cookie'

export const getUser = async (initialUser, ctx) => {
  let key = getCookie(FLAGSMITH_TOKEN, ctx?.req)

  if (initialUser) {
    if (key !== initialUser.id) {
      setCookie(FLAGSMITH_TOKEN, initialUser.id, ctx?.res)
    }

    return { ...initialUser, key: initialUser.id }
  } else {
    if (!key) {
      const { v4: uuidv4 } = await import('uuid')
      key = uuidv4()
      setCookie(FLAGSMITH_TOKEN, key, ctx?.res)
    }

    return { anonymous: true, key }
  }
}

export const identifyUser = async initialUser => {
  const user = await getUser(initialUser)
  return flagsmith.identify(user.key)
}

export const unidentifyUser = async () => {
  removeCookie(FLAGSMITH_TOKEN)
  await flagsmith.logout()

  const user = await getUser()
  return flagsmith.identify(user.key)
}
