import { useState } from 'react'
import ProfileImage from 'components/profile_image'
import ThumbnailCarousel from '../carousel'
import { fullTitle } from 'services/util/provider'
import AeditLink from 'services/link'
import { ConsultationButton } from 'components/consultation'
import { BEURO__Icon } from 'styleguide/packages/components'
import { toggleClose } from 'styleguide/packages/util/index.js'
import useScrollPosition from 'hooks/use_scroll_position'
import { capitalize } from 'services/util/format'
import { getDistance } from 'services/util/legacy'
import { pluralizeTime } from 'services/util/format'
import styles from './index.scss'

const SelectedImageDescription = ({
  provider,
  procedure,
  procedureName,
  procedureTag,
  photoAlbum,
  selectedImage = {},
  setSelectedImage,
  swapImage,
  windowSize,
  geoLocationObj,
}) => {
  const [trackData, setTrackData] = useState({
    provider_id: provider.id,
    slug: provider.slug,
    page_type: 'before_afters',
    pos: 0,
  })

  useScrollPosition({
    effect: ({ currPos }) => {
      if (currPos.y) {
        setTrackData({
          slug: provider.slug,
          page_type: 'before_afters',
          pos: currPos.y,
          provider_id: provider.id,
        })
      }
    },
  })

  const afterTime = pluralizeTime(
    selectedImage.meta.after_count,
    selectedImage.meta.after_length_of_time
  )

  const providerLocation =
    provider?.primary_practice?.location || provider?.practices?.[0]?.location
  const { city: providerCity, state_abbreviation: providerState } = providerLocation || {}

  const userLatLng = { lat: geoLocationObj?.latitude, lng: geoLocationObj?.longitude }
  const providerLatLng =
    Boolean(providerLocation?.latitude) && Boolean(providerLocation?.longitude)
      ? {
          lat: Number(providerLocation.latitude),
          lng: Number(providerLocation.longitude),
        }
      : {}

  const distance = getDistance(userLatLng, providerLatLng, 'miles')

  const modalHeader =
    windowSize.width > 768 ? (
      <>
        <h3 className="BEURO__subsection-header">{procedureName}</h3>
        <h4 className="BEURO__overline">{procedureTag}</h4>
      </>
    ) : (
      <div className="d-flex">
        <div className="profile-image-wrapper">
          <ProfileImage user={provider} className="--beuro" hideBadge />
        </div>

        <AeditLink pathname="/provider/[slug]" as={`/provider/${provider.slug}`}>
          <p className="BEURO__body provider-name">{fullTitle(provider)}</p>
        </AeditLink>
      </div>
    )

  return (
    <div className="image-description-section">
      <div className="provider-modal-header">
        {modalHeader}

        <BEURO__Icon
          className="portal__toggle"
          name="close"
          height="24"
          width="24"
          onClick={() => toggleClose('beuro-ba-modal--provider')}
        />
      </div>
      <div className="provider-info-wrapper">
        <div className="profile-image-wrapper">
          <ProfileImage user={provider} className="--beuro" hideBadge />
        </div>

        <div>
          <AeditLink pathname="/provider/[slug]" as={`/provider/${provider.slug}`}>
            <p className="BEURO__body provider-name">{fullTitle(provider)}</p>
          </AeditLink>
          <span className="d-flex">
            <p className="BEURO__overline BEURO__overline--small provider-location">
              {providerCity}, {providerState} {distance !== -1 && `(${distance} miles)`}
            </p>
          </span>
        </div>
      </div>

      <div className="image-detail mb-2">
        <BEURO__Icon className="mr-2" width="20" height="20" name="user" />
        <p className="BEURO__body BEURO__body--small">
          {selectedImage.candidate_name ? selectedImage.candidate_name + ',' : ''}{' '}
          {selectedImage.candidate_gender ? capitalize(selectedImage.candidate_gender) + ',' : ''}{' '}
          {selectedImage.candidate_age ? selectedImage.candidate_age : ''}
        </p>
      </div>

      {procedure.application_type && (
        <div className="image-detail mb-2">
          <BEURO__Icon className="mr-2" width="20" height="20" name="procedure" />
          <p className="BEURO__body BEURO__body--small">
            {procedure.application_type.display_name}
          </p>
        </div>
      )}

      {afterTime && (
        <div className="image-detail mb-2">
          <BEURO__Icon className="mr-2" width="20" height="20" name="clock" />
          <p className="BEURO__body BEURO__body--small">{afterTime} after</p>
        </div>
      )}

      <p className="BEURO__body BEURO__body--small image-caption">
        <strong className="counter">
          ({selectedImage.index + 1}/{photoAlbum.length}) -{' '}
        </strong>
        {selectedImage.caption}
      </p>

      <div className="consultation-btn-wrapper">
        <ConsultationButton trackData={trackData} provider={provider} beuro />
      </div>

      <ThumbnailCarousel
        swapImage={swapImage}
        photoAlbum={photoAlbum}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
      <style jsx>{styles}</style>
    </div>
  )
}

export default SelectedImageDescription
