import { GOOGLE_MAPS_DIRECTION_URL } from '../constants'
import Router from 'next/router'

export {
  toDollars, // 10000 => $10,000
  getErrorQuote,
  commaSeparated, // One item: 'Item Name' | More than one item: 'Item Name, Second Item Name, etc.'
  removeUrlProtocol, // Removes https:// http:// and www.
  fullLocation,
  filterSpecialties, // get rid of specialties we don't want to display
  firstSpecialty, // return first displayable specialty
  getYears, // get range of ages starting with 13
  getYearsExperience, // calculate years of experience from start year to now
  whitelist, // given a large object of values, return a new obj with only the params whitelisted
  redirectTo,
  digitsOnly, // parse string and return only integers
  objectToIds, // take id property from object and return array of ids
  heroImages, // returns hero image options from AWS
  getBase64, // for converting image files to base64 strings
  removeDuplicates, // remove duplicates from array based on given property
  getDistance, // gets distance between two lat/lngs
  getProviderProfileFlag, // returns value of a flag if is active, otherwise returns undefined
  createRandomString,
  createSecureRandomString,
  buildUrl,
  formatProviderName,
  getGoogleMapDirections,
  getProviderProfileMaxFlag,
  stripAssetUrl,
}

const getDistance = (_p1 = {}, _p2 = {}, unit = 'meters') => {
  const p1 = { lat: Number(_p1.lat), lng: Number(_p1.lng) }
  const p2 = { lat: Number(_p2.lat), lng: Number(_p2.lng) }

  // check that all the parameters were parsed as numbers
  try {
    Array.from([...Object.values(p1), ...Object.values(p2)]).forEach(val => {
      if (isNaN(val)) {
        throw new Error('passed a non-number value for lat/lng')
      }
    })
  } catch (err) {
    return -1
  }

  const rad = x => (x * Math.PI) / 180

  const R = 6378137 // Earth’s mean radius in meters
  const dLat = rad(p2.lat - p1.lat)
  const dLong = rad(p2.lng - p1.lng)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c // in meters

  switch (unit) {
    case 'meters':
      return distance
    case 'miles':
      return (distance / 1609.34).toFixed(2) // 1 mile === 1609 meters
    default:
      return distance
  }
}

const toDollars = (number, minimumFractionDigits = 0) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
  }).format(number)
}

const getErrorQuote = (statusCode = 555) => {
  const quotes = [
    `Uh oh—we might need a little tightening too.`,
    `Our engineering team is giving this page a facelift.`,
    `We’re sculpting the perfect page for you.`,
    `A peel so good, you surfaced our ${statusCode} page.`,
  ]

  return quotes[Math.floor(Math.random() * quotes.length)]
}

const commaSeparated = (arr, property) =>
  arr.map((item, index) => (
    <span key={index} style={{ display: 'inline' }}>
      {item[property]}
      {index + 1 < arr.length && `, `}
    </span>
  ))

const removeUrlProtocol = url => {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
}

const fullLocation = provider =>
  `${provider.primary_practice?.location?.city}, ${provider.primary_practice?.location?.state_abbreviation}`

function filterSpecialties(data) {
  return Object.values(data).filter(
    specialty => specialty.id !== 'none' && specialty.id !== 'other'
  )
}

function firstSpecialty(provider) {
  return filterSpecialties(provider.specialties)[0]
}
const getYears = (min = 13, max = 80) => {
  let startYear = new Date().getFullYear() - min // no one under 13 allowed by law
  let stopYear = startYear - max
  let years = []

  while (stopYear <= startYear) {
    const year = startYear--
    years.push({ label: year, value: year })
  }

  return years
}

const getYearsExperience = startYear => new Date().getFullYear() - startYear

const whitelist = (values = {}, params = []) => {
  return params.reduce((acc, item) => {
    acc[item] = values[item]
    return acc
  }, {})
}

function redirectTo(destination, { res, status } = {}) {
  if (res) {
    res.writeHead(status || 302, { Location: destination })
    res.end()
  } else {
    if (destination[0] === '/' && destination[1] !== '/') {
      Router.push(destination)
    } else {
      window.location = destination
    }
  }
}

const digitsOnly = val => {
  return val ? val.replace(/\D/g, '') : ''
}

const objectToIds = (arr, values) => {
  arr.forEach(({ read, write }) => {
    if (Object.keys(values).includes(read) && values[read]) {
      values[write] = values[read].map(obj => obj.id)
      if (read !== write) {
        delete values[read]
      }
    }
  })
  return values
}

const heroImages = [...Array(18).keys()].map(num => {
  const id = ('0' + (num + 1)).slice(-2)
  // using this instead of the Image and Transformation components bc https://github.com/cloudinary/cloudinary-react/issues/20
  return `https://res.cloudinary.com/aedit/image/upload/c_thumb,f_auto,h_192,q_auto,w_1504/v1/website/pages/providers/hero_images/provider_defaultcover_${id}.jpg`
})

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}

const getProviderProfileFlag = (user, slug) => {
  const flags = user.flags || []
  const flag = flags.find(f => f.is_active && f.slug === slug)
  return flag && flag.value
}

const getProviderProfileMaxFlag = (user, slug, defaultValue = null) => {
  const flag = getProviderProfileFlag(user, slug)
  return flag?.max ?? defaultValue
}

function createRandomString(length) {
  // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const createSecureRandomString = async lengthInBytes => {
  if (process.browser) {
    throw new Error('Creating secure random strings is not supported in browser')
  }

  const crypto = await import('crypto')

  return await new Promise((resolve, reject) => {
    crypto.randomBytes(lengthInBytes, (err, buffer) => {
      if (err) {
        return reject(err)
      }

      const str = buffer.toString('hex')
      return resolve(str)
    })
  })
}

const buildUrl = (url, query) => {
  const res = new URL(url)
  res.search = new URLSearchParams(Object.entries(query)).toString()
  return res.toString()
}

const formatProviderName = provider => {
  const name = [
    provider.first_name,
    provider.middle_initial && provider.middle_initial + '.',
    provider.last_name,
  ]
    .filter(Boolean)
    .join(' ')

  return [name, provider.professional_title_short].filter(Boolean).join(', ')
}

const getGoogleMapDirections = location => {
  const components = [location.address, location.city, location.state, location.postal_code]
  const destination = components.filter(Boolean).join(' ')
  return GOOGLE_MAPS_DIRECTION_URL + encodeURIComponent(destination)
}

const stripAssetUrl = input =>
  String(input)
    .replace(/assets\.aedit\.com/, '') // aedit url
    .replace(/images\.ctfassets\.net\/zw35sfestf1t/, '') // contentful url
