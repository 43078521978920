import dayjs from 'dayjs'
import { DateTime, Interval } from 'luxon'

export const DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

/**
 * @param {string|DateTime?} date Datetime string or a datetime object to parse.
 * @param {string?} timezone If provided then `date` is converted into this timezone.
 * @returns {DateTime} Parsed datetime object.
 */
export const createDate = (date, timezone) => {
  if (date instanceof DateTime) {
    // already parsed
  } else if (typeof date === 'string') {
    date = DateTime.fromISO(date)
  } else if (date instanceof dayjs) {
    date = DateTime.fromISO(date.toISOString())
  } else {
    date = undefined
  }

  if (date && timezone) {
    date = date.setZone(timezone)
  }

  if (!date?.isValid) {
    date = undefined
  }

  return date
}

export const firstDaysOfWeeksInMonth = date => {
  date = createDate(date)
  if (!date) {
    throw new Error('date is a required argument')
  }

  // weeks always start on Mondays in Luxon
  const from = date.startOf('month').startOf('week').plus({ days: -1 })
  const to = date.endOf('month').endOf('week').plus({ days: -1 })

  let current = from
  const results = []
  while (true) {
    results.push(current)
    current = current.plus({ days: 7 })

    if (current >= to) {
      break
    }
  }
  return results
}

export const stringify = value => {
  const parsed = createDate(value)
  return parsed?.setZone('UTC')?.toISO() || ''
}

export const isBusinessDay = (date, timezone = null) => {
  date = createDate(date, timezone)
  const day = date.weekday
  return day <= 5 // 1 is Monday, and 7 is Sunday
}

export const daysInRange = (start, end, timezone = null) => {
  start = createDate(start, timezone)
  end = createDate(end, timezone)

  if (!start) {
    throw new Error('start is a required argument')
  }
  if (!end) {
    throw new Error('end is a required argument')
  }

  const results = []
  const diff = Interval.fromDateTimes(start, end).length('days')
  for (let i = 0; i <= diff; i++) {
    const day = start.plus({ days: i }).startOf('day')
    results.push(day)
  }
  return results
}

export const getNow = timezone => createDate(DateTime.now(), timezone)

export const getToday = timezone => getNow(timezone).startOf('day')

export const getUniqueIntervals = (intervals, unit) => {
  if (!intervals) {
    throw new Error('intervals is a required argument')
  }

  if (!unit) {
    throw new Error('unit is a required argument')
  }

  const results = new Map()
  for (let interval of intervals) {
    const start = interval.start.startOf(unit)
    const end = interval.end.startOf(unit)
    const key = `${start.valueOf()}-${end.valueOf()}`
    results.set(key, { start, end })
  }
  return results.values()
}
