import React, { useContext } from 'react'
import { GlobalContext } from 'components/global'
import { authWithApple } from 'services/auth'
import { Track } from 'services/analytics'
import { Icon } from 'components/icon/Icon'
import { log } from 'services/util/log'

const AppleLogin = props => {
  const { updateUserSession, setGlobalState, toggleAuth, authOptions } = useContext(GlobalContext)
  const { className = '', onError } = props

  const handleOnClick = async () => {
    Track.appleButtonClicked()
    try {
      const res = await window.AppleID.auth.signIn()
      const params = {
        code: res.authorization.code,
        redirect_uri: process.env.APPLE_CLIENT_REDIRECT,
        user: JSON.stringify(res.user),
      }

      const {
        user,
        message = 'Sorry, there was an error. Please contact support@cs.aedit.com if this problem persists.',
      } = await authWithApple(params)

      if (message !== 'Success') {
        onError(message)
      }

      if (user) {
        await updateUserSession(user)

        const { identifyUser } = await import('services/flagsmith/user')
        await identifyUser(user)

        if (authOptions?.reloadPostAuth) {
          window.location.reload()
        }

        // toggling auth (closed) if user has completed signup a few lines below clears authOptions.
        // we need to save a current reference to the onAuthSuccess fn
        // so it is available for calling after closing the auth modal
        const onAuthSuccess = authOptions?.onAuthSuccess

        if (Boolean(user.completed_signup)) {
          toggleAuth()
          onAuthSuccess?.()
        } else {
          setGlobalState({ activeAuthScreen: 'register' })
        }
      }
    } catch (err) {
      const ignoredAppleErrors = ['popup_closed_by_user', 'user_cancelled_authorize'] // we don't need to alert user to these errors
      // errors from apple oauth are objects with 'error' key
      if (ignoredAppleErrors.includes(err.error)) return

      // fallback from err.error to err allows us to catch any undocumented apple oauth
      // errors while also supporting the tring errors we expect from backend
      onError(err.error || err)
      log('Failed to login with apple', null, { error: err })
      Track.signupError(err.error || err, 'apple')
    }
  }

  return (
    <div className={`apple-login-button mb-1 ${className}`}>
      <button className="V2__btn --light" onClick={handleOnClick}>
        <Icon name="logo-apple" stroke="transparent" />
        <p className="hidden-xs">Apple</p>
      </button>
    </div>
  )
}

export default AppleLogin
