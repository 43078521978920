import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from 'components/global'
import { Track } from 'services/analytics'

/**
 * @param {function} props.postAuth What should happen after successful auth
 * @param {?string=} props.source
 * @param {?object=} props.analytics data for tracking purposes e.g. { entity_type: 'before_afters' }
 * @param {string} props.label Label for dynamic login modal copy from contentful, optional
 */
const useAuthRequired = props => {
  const { postAuth = null, source: _source, analytics: _analytics, label } = props

  const source = _source ?? _analytics?.source
  const analytics = { ..._analytics, source }

  const [triggered, setTriggered] = useState(false)
  const [params, setParams] = useState()
  const { currentUser, toggleAuth, setGlobalState } = useContext(GlobalContext)

  if (!source && !analytics) {
    console.warn('No tracking data provided')
  }

  const attemptIsAuthedFn = params => {
    if (currentUser) {
      if (currentUser?.completed_signup) {
        try {
          postAuth?.()
        } finally {
          setTriggered(false)
        }
      } else {
        setGlobalState({
          activeAuthScreen: 'register',
          authModalOpen: true,
          authOptions: { hideWelcome: true },
        })
      }
    } else {
      try {
        toggleAuth('login', {
          loginCopyLabel: label,
          hideWelcome: true,
        })
      } finally {
        Track.gatedContentClicked({
          ...analytics,
          ...params,
        })
      }
    }
  }

  useEffect(() => {
    if (triggered) {
      attemptIsAuthedFn(params)
    }
  }, [triggered, params, currentUser])

  return (params = null) => {
    if (triggered) {
      attemptIsAuthedFn(params)
    } else {
      setParams(params)
      setTriggered(true)
    }
  }
}

export default useAuthRequired
