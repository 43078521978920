import React from 'react'
import {
  ACCESS_TOKEN,
  CLOUDINARY_REACT_INIT_OPTIONS,
  COLLECTIONS_ERROR,
  USER_TYPE,
} from 'services/constants'
import { CloudinaryContext } from 'cloudinary-react'
import { contentful } from 'services/contentful'
import { db, callApi } from 'services/config'
import { getCookie, removeCookie } from 'services/cookie'
import { log } from 'services/util/log'

const initValues = Object.freeze({
  activeAuthScreen: 'login',
  authModalOpen: false,
  collections: [],
  authOptions: {},
  favorites: [],
  shouldBlurExplicitContent: true,
  procedureFilters: null,
})

export const getFavorites = async () => {
  let favs
  try {
    favs = await callApi('GET', '/user/me/favorites')
  } catch (err) {
    log('Failed to get user favorites', null, { error: err })
  }

  if (Array.isArray(favs)) {
    return favs
  } else {
    return initValues.favorites
  }
}

const GlobalContext = React.createContext({})
// weird putting functions in component state?
// it's better for performance so descendants don't re-render unnecessarily when using context
// read why: https://bit.ly/2TuT1Pd
class GlobalWrapper extends React.PureComponent {
  state = {
    ...initValues,
    currentUser: this.props.currentUser,
    loginCopy: this.props.loginCopy,
    findAProvider: this.props.findAProvider,
    refCode: this.props.refCode || '',
    config: this.props.config,
    favorites: this.props.favorites,
    loadCollections: async () => {
      const query = {
        select: 'sys.id,sys.contentType.sys.id,fields.title,fields.slug',
      }
      try {
        const { collections } = await contentful.getCollections(query)
        this.setState({ collections })
      } catch (err) {
        log('Failed to get Contentful collections', 'error', { error: err, query })
        return COLLECTIONS_ERROR
      }
    },
    setGlobalState: (newState = {}) => {
      this.setState(newState)
    },
    toggleAuth: (toScreen = 'login', options = {}) => {
      this.setState(prevState => ({
        authModalOpen: !prevState.authModalOpen,
        activeAuthScreen: toScreen,
        authOptions: { ...options, loginCopy: prevState.loginCopy },
      }))
    },
    updateUserSession: async (currentUser = null, clearCookies = true) => {
      if (currentUser === null && clearCookies) {
        db.delete('/authenticate').finally(() => {
          removeCookie(ACCESS_TOKEN)
          removeCookie(USER_TYPE)
          global.analytics?.reset?.()
        })
      }

      this.setState({ currentUser })
    },
  }

  async componentDidMount() {
    const authType = showAuthModal()
    if (authType && !getCookie(ACCESS_TOKEN)) {
      this.state.toggleAuth(authType)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!getCookie(ACCESS_TOKEN) || (prevProps.currentUser && !this.props.currentUser)) {
      this.setState({
        currentUser: null,
        favorites: initValues.favorites,
      })
    } else if (prevProps.currentUser !== this.props.currentUser) {
      this.setState({ currentUser: this.props.currentUser })
    }

    if (this.state.currentUser && !prevState.currentUser) {
      getFavorites().then(favorites => {
        this.setState({ favorites })
      })
    }
  }

  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        <CloudinaryContext {...CLOUDINARY_REACT_INIT_OPTIONS}>
          {this.props.children}
        </CloudinaryContext>
      </GlobalContext.Provider>
    )
  }
}

// This component serves the same purpose as the above, just the HOC version
// The other way is preferred if you are using hooks since you can just useContext
const withGlobal = Component => props => {
  const {
    activeAuthScreen,
    authModalOpen,
    authOptions,
    currentUser,
    collections,
    procedureFilters,
    refCode,
    shouldBlurExplicitContent,
    setGlobalState,
    toggleAuth,
    updateUserSession,
    config,
    favorites,
  } = React.useContext(GlobalContext)

  return (
    <Component
      {...props}
      activeAuthScreen={activeAuthScreen}
      authModalOpen={authModalOpen}
      authOptions={authOptions}
      currentUser={currentUser}
      shouldBlurExplicitContent={shouldBlurExplicitContent}
      collections={collections}
      procedureFilters={procedureFilters}
      refCode={refCode}
      setGlobalState={setGlobalState}
      toggleAuth={toggleAuth}
      updateUserSession={updateUserSession}
      config={config}
      favorites={favorites}
    />
  )
}

export { GlobalWrapper, GlobalContext, withGlobal }

const showAuthModal = () => {
  const params = new URLSearchParams(window?.location?.search)
  const { authType } = Object.fromEntries([...params])

  return authType ? (authType === 'provider' ? 'provider' : 'login') : null
}
