import { USER_TYPES } from 'services/constants'
import { capitalize } from 'services/util/format'
import { cloudinaryImage } from 'services/util/cloudinary'
import { formatProviderName } from 'services/util/legacy'
import { getBase64 } from 'services/util/legacy'

export const getImageUrl = async src => {
  if (src instanceof File) {
    return await getBase64(src)
  } else if (/^http/.test(src)) {
    return cloudinaryImage(src)
  } else {
    return src
  }
}

export const serializeImage = async value => {
  if (typeof window === 'undefined') {
    throw new Error('Cannot use serializeImage on server')
  }

  if (value instanceof File) {
    return [value]
  } else if (/^http/.test(value)) {
    // Adding a dummy query parameter in order to bypass service worker cache, which most probably
    // contains the original cloudinary URL without the appropriate CORS headers.
    const cloudinaryUrl = cloudinaryImage(value)
    const url = new URL(cloudinaryUrl)
    url.searchParams.append('__aedit_nocache', 'true')

    const resp = await fetch(url.toString())
    const file = await resp.blob()
    const filename = value.substring(value.lastIndexOf('/') + 1)
    return [file, filename]
  } else {
    return [value]
  }
}

export const getBeforeAfterAltText = (set, type) => {
  const { id, procedure, candidate_gender, candidate_age } = set || {}
  return `${procedure.name} ${capitalize(type)}, ${candidate_gender} ${candidate_age} - ${id}`
}

export const getProfilePictureAltText = user => {
  switch (user._type) {
    case USER_TYPES.PROVIDER:
      return getProviderAltText(user)
    default:
      return 'profile picture'
  }
}

export const getProviderAltText = provider => {
  const name = formatProviderName(provider)

  const specialty = provider.specialties?.[0]?.display_name
  const location = provider.primary_practice?.location
  const practice = [location?.city, location?.state_abbreviation].filter(Boolean).join(', ')

  const description = [specialty, practice].filter(Boolean).join(' ')
  return [name, description].filter(Boolean).join(', ')
}
