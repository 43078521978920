import { callApi } from 'services/config'
import { inDevelopment } from 'services/env'
import { assetIdFromSrc } from 'services/util/contentful'
import formatContentfulEntry from './formatContentfulEntry'

//
// INIT
//

class ContentfulClient {
  async getAsset(id, query = null) {
    return await this._makeRequest('Asset', id, query)
  }

  async getAssets(query = null) {
    return await this._makeRequest('Asset', null, query)
  }

  async getAllAssets(query = null) {
    query = { ...query, _all_pages: true }
    return await this._makeRequest('Asset', null, query)
  }

  async getContentType(id, query = null) {
    return await this._makeRequest('ContentType', id, query)
  }

  async getContentTypes(query = null) {
    return await this._makeRequest('ContentType', null, query)
  }

  async getAllContentTypes(query = null) {
    query = { ...query, _all_pages: true }
    return await this._makeRequest('ContentType', null, query)
  }

  async getEntry(id, query = null) {
    return await this._makeRequest('Entry', id, query)
  }

  async getEntries(query = null) {
    return await this._makeRequest('Entry', null, query)
  }

  async getAllEntries(query = null) {
    query = { ...query, _all_pages: true }
    return await this._makeRequest('Entry', null, query)
  }

  async getSpace(query = null) {
    return await this._makeRequest('Space', process.env.CONTENTFUL_SPACE, query)
  }

  async _makeRequest(type, id = null, query = null) {
    const method = 'POST'
    const url = `/contentful/${type}`

    const data = {
      _preview: inDevelopment,
      ...query,
    }

    if (id) {
      data['sys.id'] = id
    }

    return await callApi(method, url, null, data)
  }
}

const client = new ContentfulClient()
export { client as contentfulClient }

//
// AEDITION ARTICLES
//
const ARTICLE_ID = 'aeditionArticles'

async function getArticles(query = {}, showHidden = false) {
  const queryDefaults = {
    select: 'sys.id,sys.createdAt,sys.updatedAt,sys.contentType.sys.id,fields',
    content_type: ARTICLE_ID,
    order: '-sys.createdAt',
    _disable_paging: true,
  }

  if (!showHidden) {
    queryDefaults['fields.collection.sys.contentType.sys.id'] = COLLECTION_ID
    queryDefaults['fields.collection.fields.isHiddenFromCorePages[ne]'] = true
  }

  const articleResponse = await client.getEntries({ ...queryDefaults, ...query })

  if (!articleResponse.items) {
    return articleResponse
  }

  const articles = articleResponse.items.map(formatContentfulEntry)

  return { articles }
}

async function getArticle(query = {}) {
  const queryDefaults = {
    select: 'sys.id,sys.createdAt,sys.updatedAt,sys.contentType.sys.id,fields',
    content_type: ARTICLE_ID,
    limit: 1,
    _disable_paging: true,
  }

  const articleResponse = await client.getEntries({ ...queryDefaults, ...query })

  if (!articleResponse.items.length) {
    return null
  }

  const article = formatContentfulEntry(articleResponse.items[0])
  article.body = await injectAssetDimensionsIntoBody(article.body)

  return article
}

//
// AEDITION COLLECTIONS
//
const COLLECTION_ID = 'aeditionCollections'

async function getCollections(query = {}) {
  const queryDefaults = {
    select: 'sys.id,sys.contentType.sys.id,fields.title,fields.slug',
    content_type: COLLECTION_ID,
    order: 'fields.order',
    'fields.isHiddenFromCorePages[ne]': true,
    _disable_paging: true,
  }
  const response = await client.getEntries({ ...queryDefaults, ...query })
  if (!response.items) {
    return response
  }
  return {
    collections: response.items.map(formatContentfulEntry),
  }
}

//
// AEDITION TAGS
//
const TAGS_ID = 'aeditionTags'

async function getTags(query = {}) {
  const queryDefaults = {
    select: 'sys.id,sys.contentType.sys.id,fields.name,fields.slug',
    content_type: TAGS_ID,
    order: 'fields.name',
  }
  const items = await client.getAllEntries({ ...queryDefaults, ...query })
  if (!items) {
    return null
  }
  return {
    tags: items.map(formatContentfulEntry),
  }
}

//
// CHILD PROCEDURE
//

const PROCEDURE_CHILD_ID = 'procedureChildPages'

async function getProcedureChildContent(slug) {
  const response = await client.getEntries({
    content_type: PROCEDURE_CHILD_ID,
    'fields.slug': slug,
    _disable_paging: true,
  })
  if (!response.items) {
    return response
  }

  return {
    childProcedures: response.items.map(formatContentfulEntry),
  }
}

//
// CONTENTFUL COPY
//
const COPY_ID = 'pageCopy'

async function getCopy(page, query = {}) {
  const queryDefaults = {
    select: 'sys.id,sys.contentType.sys.id,fields.key,fields.value',
    content_type: COPY_ID,
    'fields.page': page,
  }
  const items = await client.getAllEntries({ ...queryDefaults, ...query })
  if (!items) {
    return null
  }
  return {
    copy: items.map(formatContentfulEntry),
  }
}

//
// Initial App load fetch
//
async function getInitialAppData() {
  const responses = await Promise.all([
    // load config data
    client.getAllEntries({
      select: 'sys.id,sys.contentType.sys.id,fields.key,fields.value',
      content_type: 'config',
    }),

    // initial copy
    client.getAllEntries({
      select: 'sys.id,sys.contentType.sys.id,fields.key,fields.value',
      content_type: COPY_ID,
      'fields.page': 'login-modal',
    }),
  ])
  const contentfulConfig = {}
  const configItems = responses[0]?.map?.(formatContentfulEntry)

  configItems?.forEach(c => {
    const { key, value } = c
    contentfulConfig[key] = value
  })

  const loginCopy = {}
  const copyArray = responses[1]?.map?.(formatContentfulEntry)

  copyArray?.forEach(c => {
    const { key, value } = c
    loginCopy[key] = value
  })
  return {
    contentfulConfig,
    loginCopy,
  }
}

// PRESS
async function getPress() {
  const response = await client.getEntries({
    select:
      'sys.id,sys.contentType.sys.id,fields.url,fields.name,fields.pullQuote,fields.subtitle,fields.logo.sys.id,fields.logo.fields',
    order: 'sys.id',
    content_type: 'press',
    _disable_paging: true,
  })
  if (!response.items) {
    return response
  }
  return {
    press: response.items.map(formatContentfulEntry),
  }
}

// PROCEDURE COST
async function getProcedureCost(slug) {
  const response = await client.getEntries({
    select: 'sys.id,sys.createdAt,sys.updatedAt,sys.contentType.sys.id,fields',
    content_type: 'aeditProcedureCosts',
    'fields.slug': slug,
    limit: 1,
    _disable_paging: true,
  })

  if (!response?.items?.length) {
    return response
  }

  return {
    costData: formatContentfulEntry(response.items[0]),
  }
}

// PROCEDURE RECOVERY
async function getProcedureRecovery(slug) {
  const response = await client.getEntries({
    select: 'sys.id,sys.createdAt,sys.updatedAt,sys.contentType.sys.id,fields',
    content_type: 'aeditRecovery',
    'fields.slug': slug,
    limit: 1,
    _disable_paging: true,
  })

  if (!response?.items?.length) {
    return response
  }

  return {
    recoveryData: formatContentfulEntry(response.items[0]),
  }
}

// ADVISORY BOARD
async function getBeautyBoard() {
  const response = await client.getEntries({
    select:
      'sys.id,sys.contentType.sys.id,fields.slug,fields.seoTitle,fields.seoDescription,fields.advisoryBoards',
    content_type: 'advisoryBoardPage',
    'fields.slug': 'beauty-board',
    limit: 1,
    include: 2,
    _disable_paging: true,
  })

  if (!response?.items?.length) {
    return response
  }

  const board = formatContentfulEntry(response.items[0])

  return { board }
}

async function getEditorialProcess(select = null) {
  const response = await client.getEntries({
    select:
      select ||
      'sys.id,sys.contentType.sys.id,fields.title,fields.intro,fields.body,fields.tableOfContents',
    content_type: 'editorialProcess',
    limit: 1,
    _disable_paging: true,
  })
  if (!response?.items?.length) {
    return response
  }

  return formatContentfulEntry(response.items[0])
}

// EDitorial team content
async function getEditorialTeam() {
  const response = await client.getEntries({
    select: 'sys.id,sys.contentType.sys.id,fields',
    content_type: 'editorialPage',
    limit: 1,
    _disable_paging: true,
  })
  if (!response?.items?.length) {
    return response
  }

  return formatContentfulEntry(response.items[0])
}
async function getEditorialTeamMember() {
  const response = await client.getEntries({
    select: 'sys.id,sys.contentType.sys.id,fields',
    content_type: 'editorialTeamMember',
    _disable_paging: true,
  })
  if (!response?.items?.length) {
    return response
  }

  return response.items.map(e => formatContentfulEntry(e))
}

//
//  ASSET FETCH
//

async function getAssetsByIds(ids = []) {
  if (!ids || !ids.length) {
    return []
  }
  let resp = await client.getAssets({
    select: 'sys.id,fields.file.details.image',
    'sys.id[in]': ids.join(','),
    _disable_paging: true,
  })
  return resp.items
}

async function injectAssetDimensionsIntoBody(body) {
  // the core function supports multiple markdown bodies so all the images can be fetched at once
  // this function provides a wrapper for the more common use case of a single markdown body
  return (await injectAssetDimensionsIntoMultipleTextBodies([body]))[0]
}

async function injectAssetDimensionsIntoMultipleTextBodies(textArray = []) {
  // Regex specifically tailored to match our MD editor formatting
  const globalReg = /!\[(?<alt>[^\]]*)]\((?<src>[^)]*)\)/g
  const nonglobalReg = /!\[(?<alt>[^\]]*)]\((?<src>[^)]*)\)/
  // you need .matchAll() (>=node 12.0.0) to get capture groups on a global regex
  // .match only gives you the match. We can replicate matchAll() by using a global regex
  // to get all the matches, then map a non-global regex match() over each match to get
  // the capture groups

  let matches = []

  textArray.forEach(body => {
    const newMatches = (body || '').match(globalReg) || [] // supports undefined preview case and no match case
    matches = [...matches, ...newMatches]
  })

  const imgs = matches
    .map(match => match.match(nonglobalReg))
    .map(([_, alt, src]) => ({ alt, src }))

  // Build a map from ID to dimensions object
  const metadata = {}

  const ids = imgs.map(({ src }) => assetIdFromSrc(src))
  // In one bulk fetch, get the metadata for all the images in the body
  const resp = await getAssetsByIds(ids.filter(id => !!id))
  // populate the metadata map
  resp.forEach(({ sys, fields }) => {
    metadata[sys.id] = fields.file.details.image
  })

  const replacementFn = (match, alt, src) => {
    const id = assetIdFromSrc(src)
    if (metadata[id]) {
      return `<img src="${src}" alt="${alt}" assetDimensions={${JSON.stringify(metadata[id])}} />`
    }
    return match
  }

  // regex replace the img markdown with an html img that allows us to pass arbitrary props
  return textArray.map(body => (body || '').replace(globalReg, replacementFn))
}

export const contentful = {
  getInitialAppData,
  getArticles,
  getArticle,
  getBeautyBoard,
  getCollections,
  getTags,
  getPress,
  getEditorialProcess,
  getEditorialTeam,
  getEditorialTeamMember,
  getProcedureChildContent,
  getCopy,
  getProcedureCost,
  getProcedureRecovery,
  injectAssetDimensionsIntoBody,
  injectAssetDimensionsIntoMultipleTextBodies,
}

export const CONSTANTS = {
  ARTICLE_ID,
  COLLECTION_ID,
  TAGS_ID,
  PROCEDURE_CHILD_ID,
  COPY_ID,
}
