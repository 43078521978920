import React from 'react'

// color variables
const $BEURO__link = '#20685d'

const icons = {
  afternoon: () => (
    <>
      <path
        fill="#ebe6e0"
        d="M16 16.035c0-3.821 0-11.464 0-11.464 6.312 0 11.429 5.117 11.429 11.429 0 4.274-2.347 8.001-5.822 9.961l-5.607-9.926z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M27.029 16c0 6.091-4.938 11.029-11.029 11.029s-11.029-4.938-11.029-11.029c0-6.091 4.938-11.029 11.029-11.029s11.029 4.938 11.029 11.029z"></path>
      <path
        fill={$BEURO__link}
        d="M16 7.269c-0.252 0-0.457-0.205-0.457-0.457s0.205-0.457 0.457-0.457c0.252 0 0.457 0.205 0.457 0.457s-0.205 0.457-0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        d="M20.152 8.159c-0.065-0.244 0.079-0.495 0.323-0.56s0.494 0.079 0.56 0.323c0.065 0.244-0.079 0.495-0.323 0.56s-0.494-0.079-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M23.832 11.873c-0.244-0.065-0.389-0.316-0.323-0.56s0.316-0.389 0.56-0.323c0.244 0.065 0.389 0.316 0.323 0.56s-0.316 0.389-0.56 0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M25.189 16.457c-0.252 0-0.457-0.205-0.457-0.457s0.205-0.457 0.457-0.457c0.252 0 0.457 0.205 0.457 0.457s-0.205 0.457-0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        d="M23.519 20.711c-0.065-0.244 0.079-0.494 0.323-0.56s0.494 0.079 0.56 0.323c0.065 0.244-0.079 0.494-0.323 0.56s-0.494-0.079-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M20.451 24.391c-0.244-0.065-0.389-0.316-0.323-0.56s0.316-0.389 0.56-0.323c0.244 0.065 0.389 0.316 0.323 0.56s-0.316 0.389-0.56 0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M16 25.646c-0.252 0-0.457-0.205-0.457-0.457s0.205-0.457 0.457-0.457c0.252 0 0.457 0.205 0.457 0.457s-0.205 0.457-0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        d="M11.657 23.543c0.229 0.137 0.274 0.411 0.183 0.64-0.137 0.229-0.411 0.274-0.64 0.183-0.229-0.137-0.274-0.411-0.183-0.64 0.137-0.229 0.411-0.32 0.64-0.183z"></path>
      <path
        fill={$BEURO__link}
        d="M7.932 21.010c-0.244-0.065-0.389-0.316-0.323-0.56s0.316-0.389 0.56-0.323c0.244 0.065 0.389 0.316 0.323 0.56s-0.316 0.389-0.56 0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M6.811 16.457c-0.252 0-0.457-0.205-0.457-0.457s0.205-0.457 0.457-0.457c0.252 0 0.457 0.205 0.457 0.457s-0.205 0.457-0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        d="M7.6 11.525c-0.065-0.244 0.079-0.495 0.323-0.56s0.494 0.079 0.56 0.323c0.065 0.244-0.079 0.495-0.323 0.56s-0.495-0.079-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M11.313 8.492c-0.244-0.065-0.389-0.316-0.323-0.56s0.316-0.389 0.56-0.323c0.244 0.065 0.389 0.316 0.323 0.56s-0.316 0.389-0.56 0.323z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M16 10.286v5.714"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M18.857 20.949l-2.857-4.949"></path>
    </>
  ),
  'all-time': () => (
    <>
      <path
        fill="#ebe6e0"
        d="M4.571 16h11.429l-9.714 5.714-0.618-0.824c1.833 3.866 5.77 6.539 10.333 6.539 6.312 0 11.429-5.117 11.429-11.429s-5.117-11.429-11.429-11.429c-6.312 0-11.429 5.117-11.429 11.429z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M27.029 16c0 6.091-4.938 11.029-11.029 11.029s-11.029-4.938-11.029-11.029c0-6.091 4.938-11.029 11.029-11.029s11.029 4.938 11.029 11.029z"></path>
      <path
        fill={$BEURO__link}
        d="M8.438 20.366c-0.126-0.219-0.406-0.294-0.624-0.167s-0.294 0.406-0.167 0.624c0.126 0.219 0.406 0.294 0.624 0.167s0.294-0.406 0.167-0.624z"></path>
      <path
        fill={$BEURO__link}
        d="M11.285 23.516c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M16.342 24.847c-0.178-0.178-0.468-0.178-0.646 0s-0.179 0.468-0 0.646c0.178 0.179 0.468 0.179 0.647 0s0.178-0.468 0-0.646z"></path>
      <path
        fill={$BEURO__link}
        d="M20.99 23.729c-0.126-0.219-0.406-0.294-0.624-0.167s-0.294 0.406-0.167 0.624c0.126 0.219 0.406 0.294 0.624 0.167s0.294-0.406 0.167-0.624z"></path>
      <path
        fill={$BEURO__link}
        d="M23.839 20.156c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M25.493 15.659c-0.178-0.178-0.468-0.178-0.646 0s-0.178 0.468 0 0.646c0.178 0.178 0.468 0.178 0.646 0s0.178-0.468 0-0.646z"></path>
      <path
        fill={$BEURO__link}
        d="M24.353 11.177c-0.126-0.219-0.406-0.294-0.624-0.167s-0.294 0.406-0.167 0.624c0.126 0.219 0.406 0.294 0.624 0.167s0.294-0.406 0.167-0.624z"></path>
      <path
        fill={$BEURO__link}
        d="M20.361 8.468c0.233 0.129 0.494 0.032 0.646-0.162 0.129-0.233 0.032-0.493-0.162-0.646-0.233-0.129-0.493-0.032-0.646 0.162-0.129 0.233-0.071 0.516 0.162 0.646z"></path>
      <path
        fill={$BEURO__link}
        d="M16.305 6.508c-0.178-0.178-0.468-0.178-0.646 0s-0.178 0.468 0 0.646c0.178 0.178 0.468 0.178 0.646 0s0.179-0.468 0-0.646z"></path>
      <path
        fill={$BEURO__link}
        d="M11.802 7.814c-0.126-0.219-0.406-0.294-0.624-0.167s-0.294 0.406-0.167 0.624c0.126 0.219 0.406 0.294 0.624 0.167s0.294-0.406 0.167-0.624z"></path>
      <path
        fill={$BEURO__link}
        d="M7.925 10.962c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M7.154 15.695c-0.178-0.178-0.468-0.178-0.646 0s-0.178 0.468 0 0.646c0.178 0.178 0.468 0.178 0.646 0s0.178-0.468 0-0.646z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M11.051 18.857l4.949-2.857"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M10.286 15.926h5.714"></path>
    </>
  ),
  email: () => (
    <>
      <path
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke={$BEURO__link}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6L12 13L2 6"
        stroke={$BEURO__link}
        fill="none"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  evening: () => (
    <>
      <path
        fill="#ebe6e0"
        d="M15.962 16.022c-3.286 1.897-9.859 5.692-9.859 5.692 3.156 5.466 10.146 7.339 15.612 4.183l-5.753-9.875z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M27.029 16c0 6.091-4.938 11.029-11.029 11.029s-11.029-4.938-11.029-11.029c0-6.091 4.938-11.029 11.029-11.029s11.029 4.938 11.029 11.029z"></path>
      <path
        fill={$BEURO__link}
        d="M8.438 20.366c-0.126-0.219-0.406-0.294-0.624-0.167s-0.294 0.406-0.167 0.624c0.126 0.219 0.406 0.294 0.624 0.167s0.294-0.406 0.167-0.624z"></path>
      <path
        fill={$BEURO__link}
        d="M11.285 23.516c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M16.342 24.847c-0.178-0.178-0.468-0.178-0.646 0s-0.179 0.468-0 0.646 0.468 0.179 0.647 0c0.178-0.178 0.178-0.468 0-0.646z"></path>
      <path
        fill={$BEURO__link}
        d="M20.99 23.729c-0.126-0.219-0.406-0.294-0.624-0.167s-0.294 0.406-0.167 0.624c0.126 0.219 0.406 0.294 0.624 0.167s0.294-0.406 0.167-0.624z"></path>
      <path
        fill={$BEURO__link}
        d="M23.839 20.156c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M25.493 15.659c-0.178-0.178-0.468-0.178-0.646 0s-0.178 0.468 0 0.646c0.178 0.178 0.468 0.178 0.646 0s0.178-0.468 0-0.646z"></path>
      <path
        fill={$BEURO__link}
        d="M24.353 11.177c-0.126-0.219-0.406-0.294-0.624-0.167s-0.294 0.406-0.167 0.624c0.126 0.219 0.406 0.294 0.624 0.167s0.294-0.406 0.167-0.624z"></path>
      <path
        fill={$BEURO__link}
        d="M20.361 8.468c0.233 0.129 0.494 0.032 0.646-0.162 0.129-0.233 0.032-0.493-0.162-0.646-0.233-0.129-0.493-0.032-0.646 0.162-0.129 0.233-0.071 0.516 0.162 0.646z"></path>
      <path
        fill={$BEURO__link}
        d="M16.305 6.508c-0.178-0.178-0.468-0.178-0.646 0s-0.178 0.468 0 0.646c0.178 0.178 0.468 0.178 0.646 0s0.179-0.468 0-0.646z"></path>
      <path
        fill={$BEURO__link}
        d="M11.802 7.814c-0.126-0.219-0.406-0.294-0.624-0.167s-0.294 0.406-0.167 0.624c0.126 0.219 0.406 0.294 0.624 0.167s0.294-0.406 0.167-0.624z"></path>
      <path
        fill={$BEURO__link}
        d="M7.925 10.962c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M7.154 15.695c-0.178-0.178-0.468-0.178-0.646 0s-0.178 0.468 0 0.646c0.178 0.178 0.468 0.178 0.646 0s0.178-0.468 0-0.646z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M11.051 18.857l4.949-2.857"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M18.857 20.949l-2.857-4.949"></path>
    </>
  ),
  morning: () => (
    <>
      <path
        fill="#ebe6e0"
        d="M16 15.956c0-3.795 0-11.384 0-11.384-6.312 0-11.429 5.117-11.429 11.429l11.429-0.044z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M27.029 16c0 6.091-4.938 11.029-11.029 11.029s-11.029-4.938-11.029-11.029c0-6.091 4.938-11.029 11.029-11.029s11.029 4.938 11.029 11.029z"></path>
      <path
        fill={$BEURO__link}
        d="M16 7.269c0.252 0 0.457-0.205 0.457-0.457s-0.205-0.457-0.457-0.457c-0.252 0-0.457 0.205-0.457 0.457s0.205 0.457 0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        d="M11.848 8.159c0.065-0.244-0.079-0.495-0.323-0.56s-0.495 0.079-0.56 0.323c-0.065 0.244 0.079 0.495 0.323 0.56s0.495-0.079 0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M8.168 11.873c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M6.811 16.457c0.252 0 0.457-0.205 0.457-0.457s-0.205-0.457-0.457-0.457c-0.252 0-0.457 0.205-0.457 0.457s0.205 0.457 0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        d="M8.481 20.711c0.065-0.244-0.079-0.494-0.323-0.56s-0.495 0.079-0.56 0.323c-0.065 0.244 0.079 0.494 0.323 0.56s0.495-0.079 0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M11.549 24.391c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M16 25.646c0.252 0 0.457-0.205 0.457-0.457s-0.205-0.457-0.457-0.457c-0.252 0-0.457 0.205-0.457 0.457s0.205 0.457 0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        d="M20.343 23.543c-0.229 0.137-0.274 0.411-0.183 0.64 0.137 0.229 0.411 0.274 0.64 0.183 0.229-0.137 0.274-0.411 0.183-0.64-0.137-0.229-0.411-0.32-0.64-0.183z"></path>
      <path
        fill={$BEURO__link}
        d="M24.068 21.010c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M25.189 16.457c0.252 0 0.457-0.205 0.457-0.457s-0.205-0.457-0.457-0.457c-0.252 0-0.457 0.205-0.457 0.457s0.205 0.457 0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        d="M24.4 11.525c0.065-0.244-0.079-0.495-0.323-0.56s-0.494 0.079-0.56 0.323c-0.065 0.244 0.079 0.495 0.323 0.56s0.494-0.079 0.56-0.323z"></path>
      <path
        fill={$BEURO__link}
        d="M20.687 8.492c0.244-0.065 0.389-0.316 0.323-0.56s-0.316-0.389-0.56-0.323c-0.244 0.065-0.389 0.316-0.323 0.56s0.316 0.389 0.56 0.323z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M16 10.286v5.714"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M10.286 16h5.714"></path>
    </>
  ),
  'phone-call': () => (
    <>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M16.703 10.217c1.219 0.251 2.353 0.874 3.279 1.8s1.549 2.061 1.8 3.279"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M23.794 23.653l-0.828 0.828c-0.848 0.848-2.033 1.251-3.17 1.080-1.808-0.272-3.659-0.947-5.352-1.951-1.226-0.723-2.372-1.614-3.406-2.648s-1.925-2.18-2.648-3.407c-1.004-1.693-1.679-3.544-1.951-5.352-0.171-1.138 0.232-2.322 1.080-3.17l0.828-0.828c0.711-0.711 1.867-0.711 2.578 0l1.816 1.816c0.711 0.711 0.711 1.867 0 2.578l-0.804 0.804c-0.645 0.645-0.729 1.662-0.2 2.417 0.598 0.854 1.28 1.667 2.028 2.415s1.561 1.431 2.415 2.028c0.756 0.529 1.773 0.444 2.417-0.2l0.804-0.804c0.711-0.71 1.867-0.711 2.578 0l1.816 1.816c0.71 0.711 0.71 1.867-0 2.578z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="0.8"
        d="M17.042 6.398c1.942 0.194 4.003 1.253 5.654 2.905s2.711 3.713 2.905 5.654"></path>
    </>
  ),
  'text-message': () => (
    <>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6"
        d="M9.543 7.429c0-1.041 0.844-1.886 1.886-1.886h9.143c1.041 0 1.886 0.844 1.886 1.886v17.143c0 1.041-0.844 1.886-1.886 1.886h-9.143c-1.041 0-1.886-0.844-1.886-1.886v-17.143z"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6"
        d="M9.143 8.171h13.714"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6"
        d="M9.143 22.457h13.714"></path>
      <path
        fill="none"
        stroke={$BEURO__link}
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.6"
        d="M15.948 16.431h-0.133l-1.96 1.47v-1.47h-0.712c-0.41 0-0.743-0.333-0.743-0.743v-3.117c0-0.41 0.333-0.743 0.743-0.743h5.714c0.41 0 0.743 0.333 0.743 0.743v3.117c0 0.41-0.333 0.743-0.743 0.743h-2.909z"></path>
      <path
        fill={$BEURO__link}
        strokeWidth="0.6"
        d="M16.457 24.457c0 0.252-0.205 0.457-0.457 0.457s-0.457-0.205-0.457-0.457c0-0.252 0.205-0.457 0.457-0.457s0.457 0.205 0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        strokeWidth="0.6"
        d="M14.743 14.171c0 0.252-0.205 0.457-0.457 0.457s-0.457-0.205-0.457-0.457c0-0.252 0.205-0.457 0.457-0.457s0.457 0.205 0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        strokeWidth="0.6"
        d="M16.457 14.171c0 0.252-0.205 0.457-0.457 0.457s-0.457-0.205-0.457-0.457c0-0.252 0.205-0.457 0.457-0.457s0.457 0.205 0.457 0.457z"></path>
      <path
        fill={$BEURO__link}
        strokeWidth="0.6"
        d="M18.171 14.171c0 0.252-0.205 0.457-0.457 0.457s-0.457-0.205-0.457-0.457c0-0.252 0.205-0.457 0.457-0.457s0.457 0.205 0.457 0.457z"></path>
    </>
  ),
}

export default icons
