import React from 'react'
import styles from './index.scss'

// NOTE: passing in a mainColor it must be a string of rgb values, e.g. "0,0,0"
const Spinner = props => {
  const { shown, mainColor, style } = props

  return (
    <>
      {shown ? (
        <div
          className="spinner"
          style={{
            ...style,
            borderTop: `2px solid rgba(${mainColor}, 0.5`,
            borderRight: `2px solid rgba(${mainColor}, 0.5`,
            borderBottom: `2px solid rgba(${mainColor}, 0.5`,
            borderLeft: `2px solid rgba(${mainColor}, 1)`,
          }}
        />
      ) : null}

      <style jsx global>
        {styles}
      </style>
    </>
  )
}

Spinner.defaultProps = {
  mainColor: '181, 165, 152',
}

export default Spinner
