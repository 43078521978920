export const composeValidators =
  (...validators) =>
  value => {
    return validators.reduce((error, validator) => {
      return error || validator(value)
    }, undefined)
  }

export const required = value => {
  if (Array.isArray(value)) {
    value = !!value.length
  }

  return value ? undefined : 'Field is required'
}

export const minLength = min => value => {
  const valLength = value ? value.length : 0
  return valLength >= min ? undefined : `Must be at least ${min} characters`
}

export const maxLength = max => value => {
  value = value ? value.length : 0
  return value <= max ? undefined : `Cannot be more than ${max} characters`
}

export const exactLength = length => value =>
  value && value.length === length ? undefined : `Should be ${length} characters`

export const mustBeNumber = value => {
  return !value || isNaN(value) ? 'Must be a number' : undefined
}

export const matches = otherField => value => {
  return otherField === value ? undefined : 'Passwords must match'
}

export const url = val => {
  if (val && !val.includes('http')) {
    return 'Please make sure you have "http" in the URL'
  } else {
    return undefined
  }
}

export const zip = val => {
  if (!/^\d{5}$/.test(val)) {
    return 'Please enter a valid ZIP code'
  }
}
