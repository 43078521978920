import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import algoliasearch from 'algoliasearch'
import { createNullCache } from '@algolia/cache-common'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { Toast } from 'styleguide/packages/components'
import { callApi } from 'services/config'
import { PLAN_FLAGS } from 'services/constants'
import { alphabeticalSort } from 'services/util/abstract'
import { getProviderProfileMaxFlag } from 'services/util/legacy'
import { log } from 'services/util/log'

const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY, {
  responsesCache: createNullCache(),
  requestsCache: createNullCache(),
})
const algolia = client.initIndex(process.env.ALGOLIA_BEFORE_AFTER_COVERS_INDEX)

// changes made in algolia are not immediately visible, so this timeout specifies how much to
// wait after we updated data on backend (and therefore in algolia) and before we try to read them
const ALGOLIA_TIMEOUT = 5000 // ms

export const fetchAlbums = async provider => {
  try {
    const results = await algolia.search('', {
      filters: `provider_id = ${provider.id}`,
      facets: ['procedure.name'],
    })

    const total = results.nbHits

    const hits = results.hits.map(hit => ({
      procedure_id: hit.procedure_id,
      previewUrl: hit.after_images ? hit.after_images[0].relative_url : '',
      facetCount: hit.procedure_image_count,
    }))

    const albums = provider.procedures.map(procedure => {
      const defaultValues = {
        procedure_areas: [],
        facetCount: 0,
        previewUrl: '',
      }
      const hit = hits.find(hit => hit.procedure_id === procedure.id)

      return {
        ...defaultValues,
        ...procedure,
        ...hit,
      }
    })

    const sorted = alphabeticalSort(albums, 'name')

    return [sorted, total]
  } catch (e) {
    log('Failed to fetch albums', null, { error: e })
    toast(<Toast.Error message={e.message} />)
  }
}

export const fetchPhotoSets = async (provider, procedure) => {
  try {
    const photoSets = await callApi('get', `/provider/${provider.id}/before_and_after`, {
      procedure_id: procedure.id,
      _limit: 9999,
    })
    return photoSets
      .map(set => ({ ...set, procedure }))
      .sort((a, b) => {
        const x = dayjs(a.date_created)
        const y = dayjs(b.date_created)
        return y.isAfter(x) ? +1 : y.isBefore(x) ? -1 : 0
      })
  } catch (e) {
    log('Failed to fetch photo sets', null, { error: e })
    toast(<Toast.Error message={e.message} />)
  }
}

export const getMaxPhotoSets = (provider, totalPhotoSets) => {
  const maxCount = getProviderProfileMaxFlag(provider, PLAN_FLAGS.BEFORE_AND_AFTERS)
  const hasLimit =
    totalPhotoSets !== null &&
    totalPhotoSets !== undefined &&
    maxCount !== null &&
    maxCount !== undefined
  const reachedLimit = hasLimit && totalPhotoSets >= maxCount

  return { maxCount, hasLimit, reachedLimit }
}

const BeforeAfterContext = React.createContext()

export const useBeforeAfterContextProvider = (provider, procedureSlug = null) => {
  const [allProcedures, setAllProcedures] = useState(null)
  const [totalPhotoSets, setTotalPhotoSets] = useState()
  const isLoading = allProcedures === null || allProcedures === undefined

  const [currentProcedure, setCurrentProcedure] = useState(null)
  const [currentPhotoSets, setCurrentPhotoSets] = useState(null)

  const [upgradeDialogueToggle, setUpgradeDialogueToggle] = useState(false)
  const [modalInitialValues, setModalInitialValues] = useState()

  const { maxCount, hasLimit, reachedLimit } = useMemo(() => {
    return getMaxPhotoSets(provider, totalPhotoSets)
  }, [provider, totalPhotoSets])

  useEffect(() => {
    const initialize = async () => {
      const [allProcedures] = await fetchAllProcedures()

      if (procedureSlug) {
        const procedure = allProcedures?.find(p => p.slug === procedureSlug)
        setCurrentProcedure(procedure)

        const photoSets = await fetchPhotoSets(provider, procedure)
        setCurrentPhotoSets(photoSets)
      }
    }

    initialize()
  }, [provider, procedureSlug])

  const fetchAllProcedures = async () => {
    const [sorted, total] = await fetchAlbums(provider)
    setAllProcedures(sorted)
    setTotalPhotoSets(total)
    return [sorted, total]
  }

  const savePhotoSet = async (oldValues, newValues) => {
    const [sorted, total] = updateAlbums(allProcedures, totalPhotoSets, oldValues, newValues)
    setAllProcedures(sorted)
    setTotalPhotoSets(total)

    setTimeout(fetchAllProcedures, ALGOLIA_TIMEOUT)

    if (currentProcedure) {
      const procedure = sorted.find(p => p.id === currentProcedure.id)
      setCurrentProcedure(procedure)

      const photoSets = await fetchPhotoSets(provider, procedure)
      setCurrentPhotoSets(photoSets)
    }

    setModalInitialValues(null)
    return [sorted, total]
  }

  const addPhotoSet = (procedure = null) => upsertPhotoSet({ procedure })

  const upsertPhotoSet = (set = null) => {
    if (isLoading) {
      return
    } else if (!set?.id && reachedLimit) {
      setUpgradeDialogueToggle(true)
    } else {
      setModalInitialValues({ ...set })
    }
  }

  const value = useMemo(
    () => ({
      allProcedures,
      totalPhotoSets,
      isLoading,
      currentProcedure,
      currentPhotoSets,
      upgradeDialogueToggle,
      setUpgradeDialogueToggle,
      modalInitialValues,
      setModalInitialValues,
      savePhotoSet,
      addPhotoSet,
      upsertPhotoSet,
      maxCount,
      hasLimit,
      reachedLimit,
    }),
    [
      allProcedures,
      totalPhotoSets,
      isLoading,
      currentProcedure,
      currentPhotoSets,
      addPhotoSet,
      savePhotoSet,
      upsertPhotoSet,
      maxCount,
      hasLimit,
      reachedLimit,
    ]
  )

  return useCallback(props => <BeforeAfterContext.Provider {...props} value={value} />, [value])
}

export const useBeforeAfterContext = () => {
  return useContext(BeforeAfterContext)
}

const updateAlbums = (oldAlbums, oldTotal, oldValues, newValues) => {
  const oldProcedureId = oldValues?.procedure_id?.value
  const newProcedureId = newValues?.procedure_id

  const creating = !oldValues?.id && newValues?.id
  const updating = oldValues?.id && newValues?.id
  const deleting = oldValues?.id && !newValues?.id
  const moving = updating && oldProcedureId !== newProcedureId // moving between albums

  let albums = [...oldAlbums]
  if (deleting || moving) {
    albums = updateAlbum(albums, oldProcedureId, -1, null)
  }

  if (!deleting) {
    const insertedCount = creating || moving ? 1 : 0
    albums = updateAlbum(albums, newProcedureId, insertedCount, newValues)
  }

  const newAlbums = alphabeticalSort(albums, 'name')

  const createdCount = creating ? 1 : 0
  const deletedCount = deleting ? 1 : 0
  const newTotal = oldTotal + createdCount - deletedCount

  return [newAlbums, newTotal]
}

const updateAlbum = (albums, procedureId, insertedCount, newValues) => {
  albums = [...albums]

  const idx = procedureId && albums.findIndex(p => p.id === procedureId)
  const album = albums[idx]

  const facetCount = album.facetCount + insertedCount
  const previewUrl = facetCount ? album.previewUrl : newValues?.after_images?.[0]?.relative_url

  albums[idx] = {
    ...album,
    facetCount,
    previewUrl,
  }

  return albums
}
