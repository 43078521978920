import React from 'react'
import { Field } from 'react-final-form'
import InputMask from 'react-input-mask'
import Flatpickr from 'react-flatpickr'
import dayjs from 'dayjs'
import styles from './forms.scss'
import LocationAutocomplete from 'location-autocomplete'
import TextareaAutosize from 'react-autosize-textarea'
import classnames from 'classnames'
import Spinner from 'components/spinner'
import Icon from '../icon'
import { AeditField_Calendar } from 'styleguide/packages/form'

const telephoneUnitedStates = '^[+]?[\\(]\\d{3}[\\)]\\s\\d{3}[\\-]\\d{4}'

export const InputField = ({
  name,
  label,
  type,
  validate,
  selectOptions,
  selectLabel,
  selectValue,
  helper,
  maxLength,
  parse,
  className,
  id,
  required,
  pattern,
  defaultValue,
  helperIcon,
  options,
}) => (
  <>
    <Field name={name} validate={validate} parse={parse}>
      {({ input, meta }) => {
        const showError = meta.error && meta.touched

        const renderElement = () => {
          switch (type) {
            case 'textarea':
              return <TextareaAutosize {...input} />
            case 'date':
              return (
                <Flatpickr
                  data-alt-input
                  data-date-format="Y-m-d"
                  options={{
                    minDate: dayjs(new Date()).format('YYYY-MM-DD'),
                    nextArrow: '<i class="fa fa-right-caret"></i>',
                    prevArrow: '<i class="fa fa-left-caret"></i>',
                    locale: {
                      firstDayOfWeek: 0,
                      weekdays: {
                        shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                      },
                    },
                  }}
                  value={input.value}
                  onChange={(date, dateString) => {
                    input.onBlur()
                    input.onChange(dateString)
                  }}>
                  <input type="text" data-input />
                </Flatpickr>
              )
            case 'dateV2':
              return <AeditField_Calendar.Base {...input} />
            case 'tel':
              return (
                <InputMask
                  mask="(999) 999-9999"
                  maskChar=" "
                  value={input.value}
                  name={name}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  required={required}
                  pattern={pattern ? telephoneUnitedStates : null}
                  title="Must be a United States phone number"
                  id={id}
                />
              )
            case 'autocomplete':
              return (
                <LocationAutocomplete
                  {...input}
                  googleAPIKey={process.env.GOOGLE_API_KEY}
                  componentRestrictions={{ country: ['us', 'ca'] }}
                  onChange={val => {
                    input.onChange({ address: val.target.value })
                  }}
                  value={input.value.address}
                  onDropdownSelect={component => {
                    const { formatted_address, place_id } = component.autocomplete.getPlace()
                    input.onChange({ address: formatted_address, place_id })
                  }}
                />
              )
            case 'select':
              return (
                <select name={name} {...input}>
                  <option value="" />
                  {selectOptions.map((_, i) => (
                    <option key={i} value={selectOptions[i][selectValue]}>
                      {selectOptions[i][selectLabel]}
                    </option>
                  ))}
                </select>
              )
            case 'btn-group':
              return (
                <div className="btn-group">
                  {Object.entries(options).map(([value, render], i) => {
                    const currentValue = input.value || defaultValue
                    return (
                      <button
                        key={i}
                        className={classnames('btn', currentValue === value && 'btn--selected')}
                        onClick={e => {
                          e.preventDefault()
                          input.onChange(value)
                        }}>
                        <span className="btn__label">{render()}</span>
                      </button>
                    )
                  })}
                </div>
              )
            default:
              return <input {...input} type={type} />
          }
        }

        return (
          <div
            className={classnames('form-control-field', {
              'active-error': showError,
              [className]: !!className,
            })}>
            {type === 'select' && <i className="fa fa-caret-down" />}
            {renderElement()}
            <label htmlFor={id} className={classnames('input-label', { lifted: input.value })}>
              {label}
            </label>

            {showError ? (
              <span className="helper-text error">{meta.error}</span>
            ) : helper ? (
              <span className="helper-text">
                {helperIcon && <Icon name={helperIcon} fontSize="12px" className="mr-1" />}
                {helper}
              </span>
            ) : null}
            {maxLength && (
              <span
                className={classnames('helper-text char-length', {
                  error: input.value.length > maxLength,
                })}>
                {input.value.length}/{maxLength}
              </span>
            )}
          </div>
        )
      }}
    </Field>
    <style jsx global>
      {styles}
    </style>
  </>
)

InputField.defaultProps = {
  validate: false,
}

export const SubmitButton = ({ invalid, submitting, submitText, ...rest }) => {
  return (
    <button {...rest} type="submit" disabled={invalid}>
      {submitting ? <Spinner shown mainColor="255,255,255" /> : submitText}
    </button>
  )
}

SubmitButton.defaultProps = {
  submitText: 'Submit',
  className: `btn btn-dark`,
}
