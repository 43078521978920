import React, { useContext } from 'react'
import { GlobalContext } from 'components/global'
import { useOAuth } from 'hooks/use_oauth'
import { authWithGoogle } from 'services/auth'
import { Track } from 'services/analytics'
import { Icon } from 'components/icon/Icon'
import { GOOGLE_LOGIN_URI } from 'services/constants'
import { log } from 'services/util/log'

const errMessage =
  'Sorry, there was an error. Please contact support@cs.aedit.com if this problem persists.'

const GoogleButton = props => {
  const { updateUserSession, setGlobalState, toggleAuth, authOptions } = useContext(GlobalContext)
  const authenticate = useOAuth(GOOGLE_LOGIN_URI)
  const { className = '', onError } = props

  const handleSuccess = async tokenId => {
    const { user, message = errMessage } = await authWithGoogle(tokenId)

    if (message !== 'Success') {
      onError(message)
    }

    if (user) {
      await updateUserSession(user)

      const { identifyUser } = await import('services/flagsmith/user')
      await identifyUser(user)

      if (authOptions?.reloadPostAuth) {
        window.location.reload()
      }

      // toggling auth (closed) if user has completed signup a few lines below clears authOptions
      // we need to save a current reference to the onAuthSuccess fn
      // so it is available for calling after closing the auth modal
      const onAuthSuccess = authOptions?.onAuthSuccess

      if (Boolean(user.completed_signup)) {
        toggleAuth()
        onAuthSuccess?.()
      } else {
        setGlobalState({ activeAuthScreen: 'register' })
      }
    }
  }

  const handleFailure = err => {
    Track.signupError(err.message, 'google')
    log('Failed to login with google', null, { error: err })
    onError(errMessage)
  }

  const handleOnClick = async () => {
    try {
      Track.googleButtonClicked()

      // redirect the user to /google-login, then to google, then back to /google-login,
      // and finally return the id token
      const token = await authenticate()
      await handleSuccess(token)
    } catch (err) {
      handleFailure(err)
    }
  }

  return (
    <div className={`google-login-button mb-1 ${className}`}>
      <button className="V2__btn --light" onClick={handleOnClick}>
        <Icon name="logo-google" stroke="transparent" />
        <p className="hidden-xs">Google</p>
      </button>
    </div>
  )
}

export default GoogleButton
