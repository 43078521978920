import { useContext } from 'react'
import { GlobalContext } from 'components/global'
import AeditLink from 'services/link'

const ProfileLink = props => {
  const { currentUser, toggleAuth } = useContext(GlobalContext)

  return currentUser?.role_name === 'provider' ? (
    <AeditLink {...props} pathname="/provider-portal" />
  ) : currentUser?.completed_signup ? (
    <AeditLink {...props} as="/user/profile" pathname="/user/[name]" />
  ) : (
    <span {...props} onClick={() => toggleAuth('register')} />
  )
}

export default ProfileLink
