export const isValidPhotoAlbum = album => {
  return (
    album &&
    album.meta &&
    album.provider &&
    album.procedure &&
    album.before_image &&
    album.after_images
  )
}
