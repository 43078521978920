import React, { useState, useEffect } from 'react'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Icon } from 'styleguide/packages/components/index.js'
import { DAYS, firstDaysOfWeeksInMonth } from '../scheduling_calendar/_util'
import { classes } from 'styleguide/packages/util/index.js'

const Calendar = props => {
  const {
    initialValue = dayjs(),
    onChange = () => undefined,
    isAvailable = () => true,
    isMonthAvailable = () => true,
    canGoToMonth = () => true,
    renderUnavailable = () => null,
    className = '',
  } = props

  const [day, _setDay] = useState(initialValue && dayjs(initialValue).startOf('day'))
  const [month, _setMonth] = useState(dayjs(initialValue || undefined).startOf('month'))
  const sundays = firstDaysOfWeeksInMonth(month)
  const today = dayjs().startOf('day')

  const showPrevMonth = canGoToMonth(month.add(-1, 'month'))
  const showNextMonth = canGoToMonth(month.add(+1, 'month'))

  const setMonth = val => {
    val ? (val = val.startOf('month')) : null

    _setMonth(val)
    _setDay()
  }

  const setDay = val => {
    val ? (val = val.startOf('day')) : null

    _setDay(val)

    // switch month if user clicked on a day out of the current month
    if (val && val.month() != month.month()) {
      _setMonth(val.startOf('month'))
    }

    onChange(val)
  }

  const prevMonth = () => setMonth(month.add(-1, 'month'))
  const nextMonth = () => setMonth(month.add(+1, 'month'))

  return (
    <div className={`aedit-calendar ${className}`}>
      <div className="aedit-calendar__header mv-2">
        <Icon
          name="left-caret"
          fontSize="16px"
          className={`ml-6 ${showPrevMonth ? '' : 'NEW__disabled'}`}
          onClick={prevMonth}
        />
        <span className="NEW__overline">{month.format('MMMM YYYY')}</span>
        <Icon
          name="right-caret"
          fontSize="16px"
          className={`mr-6 ${showNextMonth ? '' : 'new__disabled'}`}
          onClick={nextMonth}
        />
      </div>

      <div className="aedit-calendar__body">
        {isMonthAvailable(month) ? (
          <>
            <div className="aedit-calendar__legend-row">
              {DAYS.map((label, i) => (
                <div key={i} className="aedit-calendar__legend-cell">
                  <span>{label}</span>
                </div>
              ))}
            </div>

            {sundays.map((sunday, i) => (
              <div key={i} className="aedit-calendar__row">
                {DAYS.map((_, i) => {
                  const dt = sunday.add(i, 'day')
                  const past = dt.isBefore(today)
                  const selected = day && dt.isSame(day)
                  const selectable = !past && isAvailable(dt)
                  const current = dt.isSame(today)

                  return (
                    <div
                      key={i}
                      onClick={() => !selected && selectable && setDay(dt)}
                      className={classes([
                        'aedit-calendar__cell',
                        {
                          'aedit-calendar__cell--selected': selected,
                          'aedit-calendar__cell--selectable': selectable,
                          'aedit-calendar__cell--current': current,
                        },
                      ])}>
                      <span>{dt.date()}</span>
                    </div>
                  )
                })}
              </div>
            ))}
          </>
        ) : (
          renderUnavailable({ setMonth })
        )}
      </div>
    </div>
  )
}

const AeditBaseCalendar = props => {
  // you can use this component with any form library
  const propsValue = props.value || ''
  const [value, setValue] = useState(propsValue)
  const [calendarActive, setCalendarActive] = useState(false)

  useEffect(() => setValue(propsValue), [propsValue])

  const onChange = val => {
    val = dayjs(val).format('YYYY-MM-DD')
    setCalendarActive(false)
    setValue(val)
    props.onChange && props.onChange(val)
  }

  return (
    <>
      <span
        className={`overlay ${calendarActive ? '' : 'hidden'}`}
        onClick={() => setCalendarActive(false)}
      />
      <input
        name={props.name}
        type="text"
        className="aedit-fake-input"
        onChange={e => e.preventDefault()}
        onClick={() => setCalendarActive(true)}
        value={value}
      />
      <Calendar
        {...props}
        initialValue={value}
        onChange={onChange}
        className={calendarActive ? '' : 'hidden'}
      />
    </>
  )
}

AeditBaseCalendar.propTypes = {
  name: PropTypes.string.optional,
  value: PropTypes.string.optional,
  onChange: PropTypes.func.optional,
}

const AeditCalendar = ({ name, ...props }) => {
  const [field, meta] = useField(props)
  const form = useFormikContext()

  return <AeditBaseCalendar {...props} {...field} onChange={val => form.setFieldValue(name, val)} />
}

AeditCalendar.propTypes = {
  name: PropTypes.string.isRequired,
}

AeditCalendar.Base = AeditBaseCalendar

export default AeditCalendar
