import React, { useEffect } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import cn from 'classnames'
import useHideIntercom from 'hooks/use_hide_intercom'
import { Icon } from 'components/icon/Icon'
import { buildPortalClasses } from './styles'

export const showPortal = NiceModal.show
export const createPortal = NiceModal.create
export const usePortal = useModal

/**
 * Custom Portal
 * @param {string} className - custom classname for the portal container
 * @param {function} onClose - function to run when portal is closed/dismissed
 * @param {string} type - type of Portal (portal-dialogue, portal-full, portal-sidebar, portal-sidebar-left)
 * @param {boolean} exitOnOverlayClick - permit dismissing portal when the overlay is clicked
 * @param {boolean} exitOnEscPress - permit dismissing portal when escape key is pressed
 * @param {boolean} showCloseIcon - show X icon in container
 * @param {boolean} hideIntercom - suppress Intercom chat bubble (mobile)
 * @param {component} children - portal contents
 * @param {string} ariaLabel - aria label for portal
 * @param {string} ariaDescription - aria description for portal
 */
export const Portal = props => {
  const {
    type = '', // types: dialogue, full, mobile-full, user-action, sidebar-right, sidebar-left
    className = '',
    onClose,
    exitOnOverlayClick = true,
    exitOnEscPress = true,
    showCloseIcon = true,
    hideIntercom = false,
    children,
    ariaLabel = 'portal',
    ariaDescription = 'portal',
  } = props

  useHideIntercom(hideIntercom)

  const handleScrollUnlock = () => {
    // check if any other instances of portal showing (excluding this one)
    const activeModals = Array.from(document.getElementsByClassName('portal-overlay'))

    // if none - remove scroll lock
    if (!activeModals.length) {
      document.body.style.removeProperty('overflow')
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => handleScrollUnlock()
  }, [])

  // handle close on esc keydown
  useEffect(() => {
    function handleEscPress(e) {
      if (!exitOnEscPress || e.isComposing || e.keyCode === 229) {
        return
      }

      if (e.key === 'Escape' || e.keyCode === 27) {
        onClose && onClose()
      }
    }

    window.addEventListener('keydown', handleEscPress, true)

    return () => window.removeEventListener('keydown', handleEscPress, true)
  }, [exitOnEscPress])

  // handle exit on overlay click
  const handleOverlayClick = e => {
    e.stopPropagation()

    if (e.target !== e.currentTarget) return

    if (exitOnOverlayClick) handleClose()
  }

  const handleClose = () => onClose && onClose()

  const { overlayCn, wrapperCn, containerCn } = buildPortalClasses(type)

  return (
    <>
      <div className={overlayCn} aria-hidden="true" />
      <div
        className={cn(wrapperCn, exitOnOverlayClick && 'cursor-pointer')}
        onClick={handleOverlayClick}>
        <div
          className={cn(containerCn, className)}
          role="dialog"
          aria-labelledby={ariaLabel}
          aria-describedby={ariaDescription}>
          <CloseIcon visible={showCloseIcon} handleClose={handleClose} />
          {children}
        </div>
      </div>
    </>
  )
}

const CloseIcon = ({ visible, handleClose }) => {
  if (!visible) return null

  return (
    <div className="z-1065 top-[18px] right-[18px] absolute cursor-pointer">
      <Icon name="close" className="cursor-pointer" onClick={handleClose} />
    </div>
  )
}

Portal.Dialogue = props => <Portal type="dialogue" {...props} />

// User must perform an action to close
// note: must implement controls.remove() or controls.hide() in the container child
// as all other methods of closing within this component are disabled
Portal.UserAction = props => (
  <Portal
    type="user-action"
    showCloseIcon={false}
    exitOnEscPress={false}
    exitOnOverlayClick={false}
    {...props}
  />
)
Portal.Full = props => <Portal type="full" {...props} />
Portal.MobileFull = props => <Portal type="mobile-full" {...props} />

// base sidebar defaults to the right side of the viewport
Portal.Sidebar = props => <Portal type="sidebar-right" {...props} />
Portal.LeftSidebar = props => <Portal type="sidebar-left" {...props} />
